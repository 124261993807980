import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Game, GameStatus } from '../domain/game';
import {
  AuditChange,
  GameEvent,
  HighlightRating,
  InterruptionType
} from '../domain/game-event';
import { AlertService } from '../services/alert.service';
import { EventService } from '../services/event.service';
import { GameTimeService } from '../services/game-time.service';
import { PuckPossessionStateService } from '../services/puck-possession-state.service';
import { GameEventInterruptionTypeService } from '../services/game-event-interruption-type.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit, OnDestroy {
  @Input()
  game: Game;

  @Input()
  selectedEventId?: string;

  @Output()
  edit = new EventEmitter<GameEvent>();

  @Output()
  update = new EventEmitter<GameEvent>();

  @Output()
  seek = new EventEmitter<GameEvent>();

  loading: boolean;
  currentPage = 1;
  itemsPerPage = 15;

  eventCount = 0;
  events: GameEvent[] = [];

  readonly gameTimeMask = [/[0-2]/, /\d/, ':', /[0-5]/, /[0-9]/];

  readonly eventTypes = [
    'face_off',
    'face_off+possession',
    'FOINT',
    'goal',
    'highlight',
    'interruption',
    'oddMenRush',
    'pass',
    'penalty',
    'puckPossession',
    'shot',
    'shot+possession',
    'time_on_ice',
    'videoTag'
  ];
  readonly shotOutcomes = ['blocked', 'miss', 'on_goal', 'iron', 'goal'];

  get interruptionTypes(): InterruptionType[] {
    return this.gameEventInterruptionTypeService.allInterruptionTypes;
  }

  readonly videoTagTypes = {
    low_break_out_under_pressure: 'Low break-out under pressure',
    board_sector_break_out_under_pressure:
      'Defensive zone board sector break-out under pressure',
    d_offensive_blue_line_puck_management:
      'Defenseman offensive blue line puck management',
    controlled_offensive_zone_entry: 'Controlled offensive zone entry'
  };

  readonly highlightTypes = [
    'goal',
    'shot',
    'save',
    'hit',
    'blooper',
    'highlight_play',
    'close_up',
    'titles',
    'bench',
    'stands',
    'penalty_box',
    'penalty_shot'
  ];

  readonly strengthStates = [
    '5-5',
    '5-4',
    '4-5',
    '4-4',
    '5-3',
    '3-5',
    '4-3',
    '3-4',
    '3-3'
  ];
  readonly teamFaceOffOutcomes = ['win', 'lost'];
  readonly passTypes = ['pass', 'chip', 'rim', 'shot_pass'];

  errors: false;
  warnings: false;
  filter: any = {
    period: '',
    team: '',
    playerNumber: '',
    eventType: '',
    shotOutcome: '',
    teamFaceOffOutcome: '',
    strengthState: '',
    videoTimeFrom: '',
    videoTimeTo: '',
    emptyNet: '',
    sortOrder: 'ASC',
    changed: false,
    errors: false,
    warnings: false,
    gameTimeFrom: '',
    gameTimeTo: '',
    highlightType: '',
    highlightPlayback: '',
    highlightRating: ''
  };

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private eventService: EventService,
    private alertService: AlertService,
    private gameTimeService: GameTimeService,
    private puckPossessionStateService: PuckPossessionStateService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private gameEventInterruptionTypeService: GameEventInterruptionTypeService
  ) {}

  ngOnInit() {
    this.applyQueryParams();
    if (
      this.game &&
      (this.game.status === GameStatus.IN_COLLECTION ||
        this.game.status === GameStatus.IN_EXTENDED_COLLECTION)
    ) {
      this.filter.sortOrder = 'DESC';
    }
    this.loadEvents();
    this.subscribeToUpdates();
  }

  private applyQueryParams() {
    const eventType = this.route.snapshot.queryParams['eventType'];
    if (eventType) {
      this.filter.eventType = eventType;
    }
    const shotOutcome = this.route.snapshot.queryParams['shotOutcome'];
    if (shotOutcome) {
      this.filter.shotOutcome = shotOutcome;
    }
    const period = this.route.snapshot.queryParams['period'];
    if (period) {
      this.filter.period = period;
    }
    const changed = this.route.snapshot.queryParams['changed'] === 'true';
    if (changed) {
      this.filter.changed = changed;
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(null);
  }

  loadEvents() {
    this.loading = true;
    this.eventService
      .getEvents(
        this.game._id,
        this.filter,
        false,
        this.currentPage - 1,
        this.itemsPerPage
      )
      .subscribe(
        (data) => {
          this.eventCount = data[0];
          this.events = data[1];
          this.loading = false;

          const eventId = this.route.snapshot.params['eventId'];
          if (eventId) {
            const selected = this.events.filter((e) => e._id === eventId);
            if (selected.length === 1) {
              this.editEvent(selected[0]);
            }
          }
        },
        (error) => {
          console.log('event loading failed', error);
          this.events = [];
          const message = error.message ?? error;
          this.alertService.showError(`Loading events failed: ${message}`);
        }
      );
  }

  subscribeToUpdates() {
    if (
      [GameStatus.FAILED, GameStatus.ABANDONED, GameStatus.COMPLETE].includes(
        this.game.status
      )
    ) {
      return;
    }
    this.eventService
      .getEventsAsStream(this.game._id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((event) => {
        console.log('Event update received', event);

        const i = this.events.findIndex((e) => e._id === event._id);
        if (event.deleted) {
          if (i > -1) {
            // delete existing event
            this.events.splice(i, 1);
          }
          const isFaceOff = event.eventType === 'face_off';
          if (isFaceOff) {
            // remove paired event
            const pairedEvent = this.events.find(
              (e) =>
                e.faceoffId &&
                e.faceoffId === event.faceoffId &&
                event._id !== e._id
            );
            if (pairedEvent) {
              const j = this.events.indexOf(pairedEvent);
              this.events.splice(j, 1);
            }
            this.gameTimeService.deleteTimeEvent(event._id, isFaceOff);
          } else if (event.eventType === 'interruption') {
            this.gameTimeService.deleteTimeEvent(event._id);
          } else if (event.eventType === 'puckPossession') {
            this.puckPossessionStateService.deleteAndDispatchPuckPossessionEvent(
              event,
              event.videoTime
            );
          }
          return;
        } else {
          if (i > -1) {
            // mark it as updated
            event.updated = true;
            this.events[i].updated = true;
          }
        }

        this.addEvent(event);
        this.update.emit(event);
      });
  }

  pageChanged(pageNumber) {
    if (this.currentPage !== pageNumber) {
      this.currentPage = pageNumber;
      this.loadEvents();
    }
  }

  gameTimeChanged(value: string, key: string) {
    if (!this.filter[key] && value.includes('_')) {
      // no need to load events
      return;
    }
    this.filter[key] = value.includes('_') ? null : value;
    if (this.filter.gameTimeTo && this.filter.gameTimeFrom) {
      if (
        this.gameTimeService.convertGameClockToSeconds(
          this.filter.gameTimeFrom
        ) <
        this.gameTimeService.convertGameClockToSeconds(this.filter.gameTimeTo)
      ) {
        [this.filter.gameTimeFrom, this.filter.gameTimeTo] = [
          this.filter.gameTimeTo,
          this.filter.gameTimeFrom
        ];
      }
    }
    this.loadEvents();
  }

  filterChanged() {
    this.currentPage = 1;
    this.loadEvents();
  }

  addEvent(event: GameEvent) {
    if (!this.matchesFilter(event)) {
      return;
    }

    const i = this.events.findIndex((e) => e._id === event._id);
    if (i > -1) {
      this.events[i] = event;
    } else {
      const j = this.events.findIndex((e) => e.gameTime < event.gameTime);
      this.events.splice(j, 0, event);
    }
  }

  editEvent(event: GameEvent) {
    this.edit.next(event);
  }

  deleteEvent(event: GameEvent) {
    if (
      !confirm(
        `Please confirm the deletion of event with type "${event.eventType}" at videoTime ${event.videoTime}`
      )
    ) {
      return;
    }

    let pairedEvent;
    if (event.eventType === 'face_off') {
      pairedEvent = this.events.find(
        (e) =>
          e.faceoffId && e.faceoffId === event.faceoffId && event._id !== e._id
      );
    }

    this.eventService.delete(event.gameId, event._id).subscribe(
      () => {
        console.log('event deleted', event);
        const i = this.events.indexOf(event);
        this.events.splice(i, 1);

        if (pairedEvent) {
          const j = this.events.indexOf(pairedEvent);
          this.events.splice(j, 1);
        }

        this.snackbar
          .open('Event deleted', 'Undo', { duration: 5000 })
          .onAction()
          .subscribe(() => {
            this.undoDelete(event);

            if (pairedEvent) {
              this.undoDelete(pairedEvent);
            }
          });
      },
      (error) => {
        console.log('delete failed', error);
        this.alertService.showError('Delete event failed: ' + error.message);
      }
    );
  }

  undoDelete(event: GameEvent): any {
    this.eventService.save(event).subscribe(
      () => {
        console.log('undo delete successful', event);
        const i = this.events.indexOf(event);
        if (!i) {
          this.events.unshift(event);
        }
        this.alertService.showInfo('Undo delete successful');
      },
      (error) => {
        console.log('undo delete failed', event);
        this.alertService.showError('Undo delete failed: ' + error.message);
      }
    );
  }

  seekEvent(onEvent: GameEvent, $event: MouseEvent): void {
    $event.preventDefault();
    this.seek.emit(onEvent);
  }

  private matchesFilter(event: GameEvent) {
    if (this.filter.eventType && this.filter.eventType !== event.eventType) {
      // Special case when we are in FOINT filter view
      if (
        this.filter.eventType === 'FOINT' &&
        ['face_off', 'penalty', 'interruption'].includes(event.eventType)
      ) {
        return true;
      }
      return false;
    } else if (
      this.filter.shotOutcome &&
      this.filter.shotOutcome !== event.shotOutcome
    ) {
      return false;
    } else if (
      this.filter.teamFaceOffOutcome &&
      this.filter.teamFaceOffOutcome !== event.teamFaceOffOutcome
    ) {
      return false;
    } else if (this.filter.period && this.filter.period !== event.period) {
      return false;
    } else if (
      this.filter.playerNumber &&
      this.filter.playerNumber !== event.playerNumber
    ) {
      return false;
    } else if (this.filter.team && this.filter.team !== event.team) {
      return false;
    } else if (
      this.filter.strengthState &&
      this.filter.strengthState !== event.strengthState
    ) {
      return false;
    } else if (!this.matchGameTimeFilter(event)) {
      return false;
    }
    if (this.filter.errors || this.filter.warnings) {
      // there is no error validation for live events
      return false;
    }

    if (
      this.filter.draft &&
      !this.eventService.isAllowedDraftEvent(event.eventType)
    ) {
      return false;
    }
    return true;
  }

  matchGameTimeFilter(event: GameEvent): boolean {
    if (!this.filter.period) {
      return true;
    }
    if (
      this.filter.gameTimeFrom &&
      this.gameTimeService.convertGameClockToGameTime(
        this.filter.gameTimeFrom,
        parseInt(this.filter.period, 10)
      ) > event.gameTime
    ) {
      return false;
    } else if (
      this.filter.gameTimeTo &&
      this.gameTimeService.convertGameClockToGameTime(
        this.filter.gameTimeTo,
        parseInt(this.filter.period, 10)
      ) < event.gameTime
    ) {
      return false;
    }
    return true;
  }

  liveDelay(event: GameEvent) {
    return this.gameTimeService.liveDelay(
      event.period,
      event.videoTime,
      new Date(event.insertDate)
    );
  }

  formatPlayers(players: string[]) {
    if (!players) {
      return '';
    }
    return players.join(', \n');
  }

  ratingChange(rating: HighlightRating) {
    this.filter.highlightRating = rating;
  }

  ratingEventChange(rating: HighlightRating, event: GameEvent) {
    event.highlightRating = rating;
    const i = this.events.findIndex((e) => e._id === event._id);
    if (i > -1) {
      // delete existing event
      this.events.splice(i, 1);
    }
    this.eventService.save(event).subscribe(
      () => {
        console.log('update rating successful', event);
        this.events.unshift(event);
        this.alertService.showInfo('Update rating successful');
      },
      (error) => {
        console.log('update rating failed', event);
        this.alertService.showError('Update rating failed: ' + error.message);
      }
    );
  }

  trackByFn(index: number, event: GameEvent) {
    return event._id;
  }

  formatAuditChanges(event: GameEvent) {
    return event.audit
      ?.filter((rec) => rec.action !== 'create')
      .map((change) => this.formatChange(change))
      .join('\n');
  }

  formatChange(change: AuditChange) {
    const changeList = Object.entries(change.changes)
      .filter((kv) => !['modificationDate', 'modificationUser'].includes(kv[0]))
      .map((kv) => `${kv[0]}=${kv[1]}`);
    if (changeList.length === 0) {
      return '';
    }
    return `${change.action} ${
      change.changes.modificationDate
    }: ${changeList.join(', ')}`;
  }
}
