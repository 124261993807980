<svg
  class="tracks"
  [class.detection-mode]="detectionMode"
  (click)="mouseClick($event)"
  (mousemove)="mouseMove($event)"
  #svg
  viewBox="0 0 1280 720"
  xmlns:svg="http://www.w3.org/1999/html"
>
  <style>
    .loading {
      font-size: 16px;
      font-family: 'Roboto', 'Helvetica', sans-serif;
      fill: white;
      text-shadow: -2px -2px 2px #0000008a;
    }
    .track {
      transition: transform 20ms ease-in;
      cursor: pointer;
    }
    .player-number {
      font-size: 16px;
      font-family: 'Roboto', 'Helvetica', sans-serif;
      fill: rgb(255, 255, 255);
    }
    .player-number-selected {
      stroke: red;
      stroke-width: 2px;
    }
    .possession-player {
      stroke: green;
      stroke-width: 2px;
    }
    .player-name {
      font-size: 10px;
      font-family: 'Roboto', 'Helvetica', sans-serif;
      fill: rgb(255 255 255);
    }
    .matched {
      stroke: rgb(0, 255, 0);
    }
    .unmatched {
      fill: rgba(255, 0, 0, 0.25);
      stroke: rgb(255, 0, 0);
    }
    .split {
      fill: rgba(255, 136, 15, 0.25);
      stroke: rgb(255, 136, 15);
    }
    .new-detection {
      fill: none;
      stroke: rgb(255, 203, 15);
    }
    .bounding-box {
      opacity: 0.25;
      stroke-width: 1;
    }
    .selected {
      opacity: 0.75;
      stroke-width: 5;
      stroke-dasharray: 5 5 5 5;
    }
    .medium-confidence {
      opacity: 0.5;
    }
    .low-confidence {
      opacity: 0.25;
    }
    .details {
      fill: rgb(72 72 72);
      font-size: 12px;
      font-family: 'Roboto', 'Helvetica', sans-serif;
      transform: translatex(-70px);
    }
  </style>

  <text class="loading" x="10" y="25" *ngIf="!onlyNumbers">
    Frame: {{ frameNumber }}
  </text>
  <text class="loading" x="150" y="25" *ngIf="detectionMode">
    Detection Mode
  </text>

  <text *ngIf="loading" class="loading" x="560" y="400">
    Loading Player Tracks...
  </text>

  <rect
    *ngIf="
      detectionStart &&
      detectionEnd &&
      detectionWidth > 0 &&
      detectionHeight > 0
    "
    class="new-detection"
    x="0"
    y="0"
    fill=""
    [ngStyle]="{
      transform:
        'translate(' +
        detectionStart[0] * cx +
        'px,' +
        detectionStart[1] * cy +
        'px)'
    }"
    [attr.width]="detectionWidth * cx"
    [attr.height]="detectionHeight * cy"
  ></rect>

  <ng-container *ngFor="let t of detections; trackBy: trackByFn">
    <ng-container *ngIf="getPlayerNumber(t)">
      <rect
        class="track"
        width="28"
        height="23"
        [attr.fill]="teamColor(t)"
        (click)="clickDetection(t)"
        rx="10"
        [ngClass]="{
          'high-confidence':
            t.trackAssignment?.numberDetectionScore >= highConfidenceThreshold,
          'medium-confidence':
            t.trackAssignment?.numberDetectionScore >=
              mediumConfidenceThreshold &&
            t.trackAssignment?.numberDetectionScore < highConfidenceThreshold,
          'low-confidence':
            t.trackAssignment?.numberDetectionScore < mediumConfidenceThreshold,
          'player-number-selected':
            t.trackAssignment?.player === selectedPlayer,
          'possession-player':
            t.trackAssignment?.player === puckPossessionPlayer
        }"
        [ngStyle]="{
          transform:
            'translate(' +
            (((t.bbox[0] + t.bbox[2]) * cx) / 2 - 14 | decimalValue) +
            'px,' +
            (t.bbox[1] * cy - 27) +
            'px)'
        }"
      />
      <text
        class="track player-number"
        (click)="clickDetection(t)"
        [ngClass]="{
          'high-confidence':
            t.trackAssignment?.numberDetectionScore >= highConfidenceThreshold,
          'medium-confidence':
            t.trackAssignment?.numberDetectionScore >=
              mediumConfidenceThreshold &&
            t.trackAssignment?.numberDetectionScore < highConfidenceThreshold,
          'low-confidence':
            t.trackAssignment?.numberDetectionScore < mediumConfidenceThreshold
        }"
        [ngStyle]="{
          transform:
            'translate(' +
            (((t.bbox[0] + t.bbox[2]) * cx) / 2 - 9 | decimalValue) +
            'px,' +
            (t.bbox[1] * cy - 10) +
            'px)'
        }"
      >
        {{ getPlayerNumber(t) }}
      </text>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!onlyNumbers">
    <text
      *ngFor="let t of detections; trackBy: trackByFn"
      class="track player-name"
      [ngStyle]="{
        transform:
          'translate(' +
          (((t.bbox[0] + t.bbox[2]) * cx) / 2 + 17 | decimalValue) +
          'px,' +
          (t.bbox[1] * cy - 30) +
          'px) rotate(-45deg)'
      }"
    >
      {{ getPlayerName(t) }}
      <ng-container *ngIf="t.trackAssignment?.team">
        {{ t.trackAssignment?.team }}
      </ng-container>
      <ng-container *ngIf="t.trackAssignment?.numberDetectionScore">
        {{ t.trackAssignment?.numberDetectionScore * 100 | number : '1.0-0' }}%
      </ng-container>
    </text>
  </ng-container>
  <!-- TODO: filter negative width/height -->
  <ng-container *ngIf="!onlyNumbers">
    <rect
      *ngFor="let t of detections; trackBy: trackByFn"
      class="track bounding-box"
      [ngClass]="{
        unmatched: !t.trackAssignment,
        matched: t.trackAssignment && !this.selectedFrom,
        split: t.trackId === selectedTrackId && this.selectedFrom,
        selected: t.trackId === selectedTrackId
      }"
      x="0"
      y="0"
      [attr.fill]="teamColor(t)"
      [attr.stroke]="teamColor(t)"
      [ngStyle]="{
        transform:
          'translate(' + t.bbox[0] * cx + 'px,' + t.bbox[1] * cy + 'px)'
      }"
      [attr.width]="(t.bbox[2] - t.bbox[0]) * cx"
      [attr.height]="(t.bbox[3] - t.bbox[1]) * cy"
      cursor="pointer"
      style=""
    ></rect>
    <text
      *ngFor="let t of detections; trackBy: trackByFn"
      class="track details"
      [attr.x]="t.bbox[0] * cx + 65"
      [attr.y]="t.bbox[3] * cx + 20"
    >
      {{ t.trackId.substr(0, 6) }}
    </text>
  </ng-container>
</svg>
<div
  class="thumbnails"
  style="
    position: absolute;
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 50px;
    padding: 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 100;
  "
>
  <img
    *ngFor="let thumbnail of thumbnails"
    class="thumbnail"
    [src]="thumbnail.src"
    [style.height.px]="130 * cy"
    (error)="hideMissingImage($event)"
  />
</div>
