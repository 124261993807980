import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportStatus, GameEvent, GoalClip } from '../../domain/game-event';
import { ShotImpactDialogComponent } from '../../game-events/shot-impact-dialog.component';
import { AlertService } from '../../services/alert.service';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-game-event-shot',
  templateUrl: './game-event-shot.component.html',
  styleUrls: ['../game-events.component.css', './game-event-shot.component.css']
})
export class GameEventShotComponent {
  @Input() event!: GameEvent;
  @Input() hasGameGoalClips!: boolean;
  @Input() players!: string[];
  @Input() opponentPlayers!: string[];

  @Output() markSlowMotionGoalClipClicked: EventEmitter<GoalClip> =
    new EventEmitter();
  @Output() exportGameEventClicked: EventEmitter<GameEvent> =
    new EventEmitter();
  @Output() eventChange = new EventEmitter<GameEvent>();

  constructor(
    private eventService: EventService,
    private dialog: MatDialog,
    private alertService: AlertService
  ) {}

  deduceAssists() {
    this.eventService.deduceAssists(this.event).subscribe((assistResult) => {
      this.eventChange.emit({ ...this.event, ...assistResult });
      if (!assistResult.assist1Id && !assistResult.assist2Id) {
        this.alertService.showInfo('No assists found');
      } else {
        this.alertService.showInfo('Assists deduced');
      }
    });
  }

  canExportClip(event: GameEvent): boolean {
    const isGoal = event.shotOutcome === 'goal';
    if (!isGoal) {
      return false;
    }
    const isMarked =
      event?.goalClip &&
      event.goalClip?.startVideoTime &&
      event?.goalClip?.endVideoTime;
    if (!isMarked) {
      return false;
    }
    const pendingTimoutAt = (event?.goalClip?.exportStatus as any)?.timeoutAt;
    return !pendingTimoutAt || new Date(pendingTimoutAt).getTime() < Date.now();
  }

  exportStatus(status: ExportStatus) {
    switch (status.status) {
      case 'not_started':
        return 'Not Exported';

      case 'pending':
        const pendingTimoutAt = new Date(status.timeoutAt).getTime();
        return pendingTimoutAt < Date.now() ? 'Timed out' : 'Pending';

      case 'transcoding':
        return 'Transcoding';

      case 'complete':
        return 'Complete';

      case 'failed':
        return 'Failed';

      default:
        return 'Unknown';
    }
  }

  vimeoUrl(vimeoVideoId: string) {
    return `https://player.vimeo.com/video/${vimeoVideoId}`;
  }

  showShotImpactDialog() {
    const dialogRef = this.dialog.open(ShotImpactDialogComponent, {
      width: '320px',
      height: '340px',
      data: {
        netImpactX: this.event.netImpactX,
        netImpactY: this.event.netImpactY
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.netImpactX && result.netImpactY) {
        this.event.netImpactX = result.netImpactX;
        this.event.netImpactY = result.netImpactY;
      }
    });
  }

  onNetTrafficChange(has_net_traffic: boolean) {
    this.event.has_net_traffic = has_net_traffic;

    if (!has_net_traffic) {
      this.event.net_traffic_causer = null;
    }
  }

  onScreenerChange(has_screen: boolean) {
    this.event.has_screen = has_screen;

    if (!has_screen) {
      this.event.screener = null;
    }
  }

  unmarkSlowMotionGoalClip() {
    console.log('unmarked goal clip', this.event);
    this.event.goalClip = null;
  }

  exportGameEvent(event: GameEvent) {
    this.exportGameEventClicked.emit(event);
  }

  markSlowMotionGoalClip(goalClip: GoalClip) {
    this.markSlowMotionGoalClipClicked.emit(goalClip);
  }
}
