<h4
  >{{ game.date | date : 'yyyy-MM-dd' }} {{ game.homeTeam }} -
  {{ game.awayTeam }}</h4
>

<select [(ngModel)]="team" class="form-control" (ngModelChange)="updateChart()">
  <option [value]="game.homeTeam">{{ game.homeTeam }}</option>
  <option [value]="game.awayTeam">{{ game.awayTeam }}</option>
</select>
<select
  [(ngModel)]="period"
  class="form-control"
  (ngModelChange)="updateChart()"
>
  <option [value]="'1'">Period 1</option>
  <option [value]="'2'">Period 2</option>
  <option [value]="'3'">Period 3</option>
  <option [value]="'4'">OT 1</option>
  <option [value]="'5'">OT 2</option>
  <option [value]="'6'">OT 3</option>
</select>
<div class="timeline" #timeline></div>
