import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GamePeriod } from '../domain/game-event';
import { Detection } from './detection';
import { Frame } from './frame';
import { Track } from './track';
import { TrackAssignment } from './track-assignment';

export interface PlayerThumbnail {
  number: number;
  trackId: string;
  src: string;
  width?: number;
  height?: number;
}

@Injectable()
export class TrackingService {
  _detectionsOffset: number = null;
  _frames: Frame[] = [];
  _tracks: Track[] = [];

  private baseUrl = environment.API_HOST + '/api/tracking';

  constructor(private http: HttpClient) {}

  initialize(gameId: string) {
    this._detectionsOffset = 0;
    this._frames = [];
    this._tracks = [];
  }

  get detectionsOffset() {
    return this._detectionsOffset;
  }

  getTrack(trackId: string) {
    return this._tracks.find((t) => t.trackId === trackId);
  }

  getTracks(
    gameId: string,
    frameStart: number,
    frameEnd: number
  ): Observable<Track[]> {
    const params = new HttpParams()
      .set('frame_start', String(frameStart))
      .set('frame_end', String(frameEnd));
    return this.http.get<Track[]>(`${this.baseUrl}/${gameId}/tracks`, {
      params
    });
  }

  getTrackAssignments(gameId: string): Observable<TrackAssignment[]> {
    return this.http.get<TrackAssignment[]>(
      `${this.baseUrl}/${gameId}/assignments`
    );
  }

  saveTrackAssignment(
    gameId: string,
    frame: number,
    id: string,
    trackId: string,
    player: string,
    team: string,
    from: string
  ): Observable<TrackAssignment> {
    return this.http.post<TrackAssignment>(
      `${this.baseUrl}/${gameId}/tracks/${trackId}/assignments`,
      { id, frame, player, team, from }
    );
  }

  deleteTrackAssignment(
    gameId: string,
    trackId: string,
    id: string
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.baseUrl}/${gameId}/tracks/${trackId}/assignments/${id}`
    );
  }

  getPlayerThumbnails(
    gameId: string,
    period: string,
    trackId: string,
    numCrops: number
  ) {
    const params = new HttpParams()
      .set('numCrops', String(numCrops))
      .set('period', period);
    return this.http.get<PlayerThumbnail[]>(
      `${this.baseUrl}/${gameId}/tracks/${trackId}/thumbnails`,
      { params }
    );
  }

  getDetections(
    gameId: string,
    frameRate: number,
    frameNumber: number,
    period: GamePeriod,
    homeTeamStartPosition: 'left' | 'right'
  ): Observable<Detection[]> {
    const pageSize = Math.floor(frameRate * 60);
    const loadingDistance = Math.floor(frameRate * 10);
    const remainingStart = frameNumber - this._detectionsOffset;
    const remainingEnd = this._detectionsOffset + pageSize - frameNumber;

    if (
      this._detectionsOffset === null ||
      remainingEnd < loadingDistance ||
      remainingStart < 0
    ) {
      let nextOffset;
      if (this._detectionsOffset === null) {
        nextOffset = Math.floor(frameNumber / pageSize) * pageSize;
      } else if (remainingEnd < 0) {
        nextOffset = Math.floor(frameNumber / pageSize) * pageSize;
      } else if (remainingEnd < loadingDistance) {
        nextOffset = frameNumber;
      } else if (remainingStart < 0) {
        nextOffset = Math.floor(frameNumber / pageSize) * pageSize;
      }

      if (this._detectionsOffset === nextOffset) {
        // skip request
        return of(this.findVisibleDetections(frameNumber));
      }
      this._detectionsOffset = nextOffset;

      const flatMap = (arr, f) => arr.map(f).reduce((a, b) => a.concat(b), []);

      const frameStart = nextOffset;
      const frameEnd = frameStart + pageSize;
      return this.getTracks(gameId, frameStart, frameEnd).pipe(
        map((tracks) => {
          this._tracks = this.interpolateFrames(tracks);
          const frames: Frame[] = new Array(nextOffset + pageSize);
          const allDetections = flatMap(tracks, (track) =>
            track.frames.map((f) => ({
              frame: f.frame,
              bbox: f.bbox,
              iceRinkCoordinates: this.transformCoordinates(
                f.iceRinkCoordinates,
                period,
                homeTeamStartPosition
              ),
              trackId: track.trackId
            }))
          );
          const visibleDetections = allDetections.filter((d) =>
            this.isBoundingBoxVisible(d.bbox)
          );
          const detectionsByFrame = this.groupBy(visibleDetections, 'frame');
          for (let i = 0; i < frames.length; i++) {
            frames[i] = detectionsByFrame[i] || [];
          }
          this._frames = frames;
          return this._frames[frameNumber] || [];
        })
      );
    }
    return of(this.findVisibleDetections(frameNumber));
  }

  private transformCoordinates(
    iceRinkCoordinates: number[],
    period: GamePeriod,
    homeTeamStartPosition: 'left' | 'right'
  ) {
    if (!iceRinkCoordinates) {
      return iceRinkCoordinates;
    }
    const rotateField =
      (['2', '4', '6'].includes(period) &&
        (!homeTeamStartPosition || homeTeamStartPosition === 'left')) ||
      (['1', '3', '5', '7'] && homeTeamStartPosition === 'right');
    if (rotateField) {
      return iceRinkCoordinates.map((c) => 1 - c);
    }
    return iceRinkCoordinates;
  }

  private interpolateFrames(tracks: Track[]): Track[] {
    console.log('interpolating frames...');
    const linear = (x1, x2) => (x1 + x2) * 0.5;

    return tracks.map((track) => {
      const interpolatedTrack = { ...track };
      const start = Math.min(...track.frames.map((t) => t.frame));
      const end = Math.max(...track.frames.map((t) => t.frame));

      for (let i = start + 1; i < end; i++) {
        const t_cur = track.frames.find((t) => t.frame === i);
        const t_prev = track.frames.find((t) => t.frame === i - 1);
        const t_next = track.frames.find((t) => t.frame === i + 1);
        if (!t_cur && t_prev && t_next) {
          const frame: Detection = {
            frame: i,
            bbox: [
              linear(t_prev.bbox[0], t_next.bbox[0]),
              linear(t_prev.bbox[1], t_next.bbox[1]),
              linear(t_prev.bbox[2], t_next.bbox[2]),
              linear(t_prev.bbox[3], t_next.bbox[3])
            ],
            iceRinkCoordinates: []
          };
          if (t_prev.iceRinkCoordinates && t_next.iceRinkCoordinates) {
            frame.iceRinkCoordinates = [
              linear(
                t_prev.iceRinkCoordinates[0],
                t_next.iceRinkCoordinates[0]
              ),
              linear(t_prev.iceRinkCoordinates[1], t_next.iceRinkCoordinates[1])
            ];
          }
          interpolatedTrack.frames.push(frame);
        }
      }
      return interpolatedTrack;
    });
  }

  private isBoundingBoxVisible(bbox: number[]) {
    return (
      bbox.every((val) => val > 0) && // hide bounding boxes with negative coordinates - avoids SVG error
      bbox[2] - bbox[0] > 0 && // negative width
      bbox[3] - bbox[1] > 0 // negative height
    );
  }

  saveDetection(
    gameId: string,
    frame: number,
    bbox: number[]
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/${gameId}/detections`, [
      { frame, bbox, obj: null, trackId: null }
    ]);
  }

  private groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  private findVisibleDetections(frameNumber: number): Detection[] {
    return this._frames[frameNumber] || [];
  }
}
