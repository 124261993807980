import { Opponent, GameEvent, StrengthState } from '../domain/game-event';
import { TimeOnIceService } from '../time-on-ice/time-on-ice.service';
import { Injectable } from '@angular/core';
import {
  isAwayTeamEmptyNetChange,
  isHomeTeamEmptyNetChange,
  strengthStateChange
} from '../state/actions/game-event.action';
import { Store } from '@ngrx/store';

import { penaltiesChange } from '../state/actions/game.action';
import { GlobalState } from '../state/reducers';

@Injectable()
export class StrengthStateService {
  penalties: GameEvent[] = [];
  timeOnIce: GameEvent[] = [];

  readonly strengthStates = [
    '5-5',
    '5-4',
    '4-5',
    '4-4',
    '5-3',
    '3-5',
    '4-3',
    '3-4',
    '3-3'
  ];

  constructor(
    private timeOnIceService: TimeOnIceService,
    private store: Store<GlobalState>
  ) {}

  public init(gameId: string): void {
    this.timeOnIceService.loadPenalties(gameId).subscribe((events) => {
      this.penalties = events.sort((a, b) => a.videoTime - b.videoTime);
    });

    this.timeOnIceService.loadTimeOnIce(gameId).subscribe((events) => {
      this.timeOnIce = events.sort((a, b) => a.videoTime - b.videoTime);
    });
  }

  public updateActivePenalties(gameTime: number) {
    this.store.dispatch(
      penaltiesChange({ penalties: this.findActivePenalties(gameTime) })
    );
  }

  public addAndDispatchActivePenalty(
    newPenaltyEvent: GameEvent,
    gameTime: number
  ) {
    const i = this.penalties.findIndex((e) => e._id === newPenaltyEvent._id);
    if (i === -1) {
      this.penalties.push(newPenaltyEvent);
    } else {
      this.penalties[i] = newPenaltyEvent;
    }
    this.penalties.sort((a, b) => a.videoTime - b.videoTime);
    this.store.dispatch(
      penaltiesChange({
        penalties: structuredClone(this.findActivePenalties(gameTime))
      })
    );
  }

  public updateStrengthState(videoTime: number) {
    const strengthState = this.deriveStrengthState(videoTime, this.penalties);
    this.store.dispatch(strengthStateChange({ strengthState }));
  }

  public deriveStrengthState(
    videoTime: number,
    events: GameEvent[]
  ): StrengthState {
    const penaltyEvents = events
      .filter((e) => e.eventType === 'penalty')
      .sort((a, b) => a.videoTime - b.videoTime);

    const event = this.findLastUpToVideoTime(penaltyEvents, videoTime);
    if (event) {
      return event.strengthState;
    }
    return '5-5';
  }

  public addAndDispatchTimeOnIce(newTimeOnIceEvent: GameEvent) {
    const i = this.timeOnIce.findIndex((e) => e._id === newTimeOnIceEvent._id);
    if (i === -1) {
      this.timeOnIce.push(newTimeOnIceEvent);
    } else {
      this.timeOnIce[i] = newTimeOnIceEvent;
    }
    this.timeOnIce.sort((a, b) => a.videoTime - b.videoTime);
  }

  public deleteAndDispatchTimeOnIce(removeTimeOnIceEvent: GameEvent) {
    const i = this.timeOnIce.findIndex(
      (e) => e._id === removeTimeOnIceEvent._id
    );
    if (i > -1) {
      this.timeOnIce.splice(i, 1);
    }
  }

  public updateHomeTeamEmptyNet(videoTime: number) {
    const isEmpty = this.deriveOpponentEmptyState(
      videoTime,
      this.timeOnIce,
      'home'
    );
    this.store.dispatch(
      isHomeTeamEmptyNetChange({ isHomeTeamEmptyNet: isEmpty })
    );
  }

  public updateAwayTeamEmptyNet(videoTime: number) {
    const isEmpty = this.deriveOpponentEmptyState(
      videoTime,
      this.timeOnIce,
      'away'
    );
    this.store.dispatch(
      isAwayTeamEmptyNetChange({ isAwayTeamEmptyNet: isEmpty })
    );
  }

  public deriveOpponentEmptyState(
    videoTime: number,
    events: GameEvent[],
    team: Opponent
  ): boolean {
    const timeOnIceEvents = events
      .filter((e) => e.eventType === 'time_on_ice')
      .sort((a, b) => a.videoTime - b.videoTime);

    const event = this.findLastUpToVideoTime(timeOnIceEvents, videoTime);
    if (event) {
      return team === 'home'
        ? event.isHomeTeamEmptyNet
        : event.isAwayTeamEmptyNet;
    }
    return false;
  }

  public deriveTimeOnIceOffEventStrengthState(gameTime): StrengthState {
    let result: StrengthState = '5-5';
    const eventsBefore = this.penalties
      .filter((e) => !['10', '20'].includes(e.penaltyDuration))
      .filter((e) => e.gameTime < gameTime);
    if (eventsBefore.length > 0) {
      result = eventsBefore[eventsBefore.length - 1].strengthState;
    }
    return result;
  }

  private findLastUpToVideoTime(
    events: GameEvent[],
    videoTime: number
  ): GameEvent {
    let result: GameEvent;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < events.length; i++) {
      if (events[i].videoTime > videoTime) {
        break;
      }
      if (!result || events[i].videoTime > result.videoTime) {
        result = events[i];
      }
    }
    return result;
  }

  private findActivePenalties(gameTime: number): GameEvent[] {
    const activePenalties = [];
    let currentGameTime = 0;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.penalties.length; i++) {
      const penalty = this.penalties[i];
      currentGameTime = penalty.gameTime;
      if (currentGameTime > gameTime) {
        continue;
      }
      if (penalty.penaltyType === 'start') {
        activePenalties.push(penalty);
      } else if (penalty.penaltyType === 'expiration') {
        const index = activePenalties.findIndex(
          (p) =>
            p.penaltyType === 'start' &&
            ((p.penaltyId &&
              penalty.penaltyId &&
              p.penaltyId === penalty.penaltyId) ||
              p.playerNumber === penalty.playerNumber)
        );
        if (index > -1) {
          activePenalties.splice(index, 1);
        } else {
          console.warn(
            'Could not find penalty start event for expiration event',
            penalty
          );
        }
      } else {
        console.warn('Unknown penalty type ' + penalty.penaltyType, penalty);
      }
    }
    return activePenalties;
  }

  public changeStrengthState(
    strengthState: StrengthState,
    isHomeTeam: boolean,
    direction: 1 | -1
  ): StrengthState | undefined {
    const strength = strengthState.split('-');
    if (isHomeTeam) {
      strength[0] = (parseInt(strength[0], 10) + direction).toString();
    } else {
      strength[1] = (parseInt(strength[1], 10) + direction).toString();
    }
    const newStrength = strength.join('-');
    if (!this.strengthStates.includes(newStrength)) {
      console.warn('Invalid strength state' + newStrength);
      return;
    }
    return newStrength as StrengthState;
  }
}
