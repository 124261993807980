import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TeamService } from '../services/team.service';
import { MatDialog } from '@angular/material/dialog';
import { EditTeamDialogComponent } from './edit-team-dialog/edit-team-dialog.component';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { Team } from '../domain/team';
import { ExternalIdsDialogComponent } from '../shared/external-ids-dialog/external-ids-dialog.component';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  loading: boolean;
  season: string;
  seasonType: string;
  leagueId: string;

  columns = ['imageUrl', 'shortName', 'name', 'actions'];

  constructor(
    private teamService: TeamService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<any>([]);
    this.season = this.route.snapshot.queryParams.season;
    this.seasonType = this.route.snapshot.queryParams.seasonType;
    this.leagueId = this.route.snapshot.params.leagueId;
    this.loadData();
  }

  loadData() {
    this.loading = true;
    this.teamService
      .getTeams(this.leagueId, this.season, this.seasonType)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data) => (this.dataSource.data = data));
  }

  showCreateTeamDialog(team) {
    const dialogRef = this.dialog.open(EditTeamDialogComponent, {
      width: '800px',
      data: { team, leagueId: this.leagueId }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.teamService
          .createOrUpdateTeam({
            ...result,
            leagueId: parseInt(this.leagueId, 10)
          })
          .subscribe((_team) => {
            _team[0].additionalLeagues = _team[0].additionalLeagues.filter(
              (l) => l !== this.leagueId
            );
            const index = this.dataSource.data.findIndex(
              (item) => item.id === _team[0].id
            );
            if (index >= 0) {
              this.dataSource.data[index] = _team[0];
              this.dataSource.data = [...this.dataSource.data];
            } else {
              this.dataSource.data = [...this.dataSource.data, _team[0]];
            }
          });
      }
    });
  }

  deleteTeam(team) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Confirm deletion of ${team.shortName + ' - ' + team.name}`,
          message: `This will hide the team and it's data, but not delete any actual data.`,
          okButtonCaption: 'Delete Team'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.teamService.deleteTeam(team.id).subscribe(() => {
            const index = this.dataSource.data.findIndex(
              (item) => item.id === team.id
            );
            this.dataSource.data.splice(index, 1);
            this.dataSource._updateChangeSubscription();
          });
        }
      });
  }

  logoUrl(imageUrl: string) {
    if (imageUrl?.startsWith('https://')) {
      return imageUrl;
    }
    return 'https://files.eliteprospects.com/layout/logos/' + imageUrl;
  }

  updateExternalIDs(event: MouseEvent, team: Team): void {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ExternalIdsDialogComponent, {
      width: '350px',
      data: { team }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.updated) {
        this.loadData();
      }
    });
  }
}
