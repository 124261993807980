<div [class.edit-mode]="editMode">
  <div *ngIf="game.isLiveDraftEvents" class="realtime-indicator"
    ><mat-icon
      matTooltip="Collection of face-offs, interruptions, penalties and shots is optimized for realtime."
      >speed</mat-icon
    ></div
  >

  <label>Event Type:</label>
  <strong>{{ eventType }}</strong>
  <ul *ngIf="!eventType" style="columns: 2">
    <li
      ><strong>f</strong>ace off
      <mat-icon class="realtime-icon" *ngIf="game.isLiveDraftEvents"
        >speed</mat-icon
      ></li
    >
    <li
      ><strong>i</strong>nterruption
      <mat-icon class="realtime-icon" *ngIf="game.isLiveDraftEvents"
        >speed</mat-icon
      ></li
    >
    <li><strong>o</strong>dd-men-rush</li>
    <li><strong>p</strong>ass</li>
    <li
      >penalt<strong>y</strong>
      <mat-icon class="realtime-icon" *ngIf="game.isLiveDraftEvents"
        >speed</mat-icon
      ></li
    >
    <li
      ><strong>s</strong>hot
      <mat-icon class="realtime-icon" *ngIf="game.isLiveDraftEvents"
        >speed</mat-icon
      ></li
    >
    <li><strong>u</strong> puck possession</li>
    <li><strong>v</strong>ideo tag</li>
    <li *ngIf="game?.isHighlightCollection"><strong>h</strong>ighlight</li>
    <li>period s<strong>t</strong>art</li>
    <li>period <strong>e</strong>nd</li>
  </ul>
  <div *ngIf="requiresTeam()">
    <label>Team:</label>
    <strong>{{ team }}</strong>
    <ul *ngIf="!team" style="display: inline; padding-left: 4px">
      <li style="display: inline"><strong>h</strong> {{ game?.homeTeam }}</li>
      <li style="display: inline; margin-left: 4px"
        ><strong>a</strong> {{ game?.awayTeam }}</li
      >
    </ul>
  </div>
  <div *ngIf="requiresPlayer()">
    <label>Player:</label>
    <label *ngIf="player">
      <strong [class.warning]="playerWarning">{{ player }}</strong>
      <label class="position" [matTooltip]="'Position ' + playerPosition">{{
        playerPosition
      }}</label>
      <span
        class="shoots"
        [matTooltip]="'Shoots ' + playerShoots"
        [class.shoots-left]="playerShoots === 'LEFT'"
        [class.shoots-right]="playerShoots === 'RIGHT'"
        >{{ playerShoots }}</span
      >
    </label>
    <label *ngIf="!player"
      ><strong>{{ playerNumberString }}</strong></label
    >
    <label *ngIf="!player && !playerNumberString"
      ><i>Type jersey number</i></label
    >
  </div>
  <div *ngIf="eventType === 'interruption'">
    <ul *ngIf="!interruptionType" style="columns: 2">
      <li><strong>i</strong>cing</li>
      <li><strong>o</strong>ffside</li>
      <li><strong>k</strong> puck stopped by goalkeeper</li>
      <li><strong>p</strong>uck out</li>
      <li><strong>g</strong>oal</li>
      <li>penalt<strong>y</strong></li>
      <li><strong>u</strong>nspecified</li>
    </ul>
    <div
      *ngIf="
        interruptionType === 'period_start' || interruptionType === 'period_end'
      "
    >
      <label>Interruption Reason:</label>
      <strong>{{ interruptionType }}</strong>
      <div *ngIf="interruptionType === 'period_start'">
        <app-period
          [(ngModel)]="period"
          (ngModelChange)="onPeriodChange($event)"
        ></app-period>
      </div>
    </div>

    <div
      *ngIf="
        interruptionType &&
        interruptionType !== 'period_start' &&
        interruptionType !== 'period_end'
      "
    >
      <label>Interruption Reason:</label>
      <strong>{{ interruptionType }}</strong>
    </div>
    <br />
  </div>
  <div *ngIf="eventType === 'pass'">
    <label>Pass Type:</label>
    <strong>{{ passType }}</strong>
    <ul *ngIf="!passType">
      <li><strong>p</strong>ass</li>
      <li><strong>c</strong>hip</li>
      <li><strong>r</strong>im</li>
      <li><strong>s</strong>hot_pass</li>
    </ul>

    <label *ngIf="passType === 'pass' || passType === 'shot_pass'"
      >Receiver Number:</label
    >
    <label *ngIf="passReceiverPlayer"
      ><strong>{{ passReceiverPlayer }}</strong></label
    >
    <label *ngIf="!passReceiverPlayer"
      ><strong>{{ passReceiverPlayerNumberString }}</strong></label
    >
    <div *ngIf="passType === 'pass' || passType === 'shot_pass'">
      <label>Pass Outcome:</label>
      <strong>{{ passOutcome }}</strong>
      <ul *ngIf="!passOutcome">
        <li><strong>c</strong>omplete</li>
        <li><strong>i</strong>ncomplete</li>
      </ul>
    </div>
  </div>
  <div *ngIf="eventType === 'shot'">
    <label>Shot Outcome:</label>
    <strong>{{ shotOutcome }}</strong>
    <span
      *ngIf="
        game &&
        !game.isLiveCollection &&
        game.dataSet &&
        game.dataSet === 'FULL' &&
        netImpactX &&
        netImpactY
      "
    >
      ({{ netImpactX }}/{{ netImpactY }})</span
    >
    <ul *ngIf="!shotOutcome">
      <li><strong>b</strong>locked</li>
      <li><strong>m</strong>iss</li>
      <li><strong>o</strong>n_goal</li>
      <li><strong>g</strong>oal</li>
      <li><strong>i</strong>ron</li>
    </ul>
    <div *ngIf="shotOutcome === 'blocked'">
      <label>Shot Blocker:</label>
      <label *ngIf="shotBlockerPlayer"
        ><strong>{{ shotBlockerPlayer }}</strong></label
      >
      <label *ngIf="!shotBlockerPlayer"
        ><strong>{{ shotBlockerPlayerNumberString }}</strong></label
      >
    </div>
    <div *ngIf="shotOutcome">
      <label>Shot Scenario:</label>
      <strong>{{ shotScenario }}</strong>
      <ul *ngIf="!shotScenario">
        <li><strong>r</strong>egular</li>
        <li>r<strong>e</strong>bound</li>
        <li><strong>q</strong>uick_release</li>
        <li><strong>o</strong>ne_timer</li>
      </ul>
    </div>
    <div *ngIf="shotOutcome && shotScenario">
      <label>Shot Type:</label>
      <strong>{{ shotType }}</strong>
      <ul *ngIf="!shotType">
        <li><strong>w</strong>rist shot</li>
        <li><strong>s</strong>lap shot</li>
        <li><strong>b</strong>ackhand shot</li>
        <li><strong>d</strong>eflection</li>
      </ul>
    </div>
    <div *ngIf="shotOutcome && shotScenario && shotType === 'deflection'">
      <label>Deflector:</label>
      <strong>{{ deflectorPlayer }}</strong>
    </div>
    <div *ngIf="shotOutcome && shotScenario && shotType">
      <label>Net Traffic:</label>
      <strong *ngIf="has_net_traffic !== undefined">{{
        has_net_traffic === '0' ? 'no' : 'yes'
      }}</strong>
      <ul *ngIf="!has_net_traffic">
        <li><strong>n</strong>o</li>
        <li><strong>y</strong>es</li>
      </ul>
    </div>
    <div *ngIf="has_net_traffic === '1'">
      <label>Net Traffic Causer:</label>
      <label *ngIf="netTrafficCauserPlayer"
        ><strong>{{ netTrafficCauserPlayer }}</strong></label
      >
      <label *ngIf="!netTrafficCauserPlayer"
        ><strong>{{ netTrafficCauserPlayerNumberString }}</strong></label
      >
    </div>
    <div *ngIf="has_net_traffic">
      <label>Screen:</label>
      <strong *ngIf="has_screen !== undefined">{{
        has_screen === '0' ? 'no' : 'yes'
      }}</strong>
      <ul *ngIf="!has_screen">
        <li><strong>n</strong>o</li>
        <li><strong>y</strong>es</li>
      </ul>
    </div>
    <div *ngIf="has_screen === '1'">
      <label>Screener:</label>
      <label *ngIf="screenerPlayer"
        ><strong>{{ screenerPlayer }}</strong></label
      >
      <label *ngIf="!screenerPlayer"
        ><strong>{{ screenerPlayerNumberString }}</strong></label
      >
    </div>
  </div>
  <div *ngIf="eventType === 'face_off'">
    <label>Team Outcome:</label>
    <strong>{{ teamFaceOffOutcome }}</strong>
    <ul *ngIf="!teamFaceOffOutcome" style="display: inline; padding-left: 0">
      <li style="display: inline"><strong>w</strong>in</li>
      <li style="display: inline; margin-left: 4px"><strong>l</strong>ose</li>
    </ul>

    <label style="margin-left: 4px">Opponent:</label>
    <label *ngIf="faceOffOpponentPlayer"
      ><strong [class.warning]="faceOffOpponentPlayerWarning">{{
        faceOffOpponentPlayer
      }}</strong></label
    >
    <label *ngIf="!faceOffOpponentPlayer"
      ><strong>{{ faceOffOpponentPlayerNumberString }}</strong></label
    >
  </div>
  <div *ngIf="eventType === 'penalty' && !game.isLiveDraftEvents">
    <label>Fouled Player:</label>
    <label *ngIf="fouledPlayer"
      ><strong>{{ fouledPlayer }}</strong></label
    >
    <label *ngIf="!fouledPlayer"
      ><strong>{{ fouledPlayerNumberString }}</strong></label
    >
  </div>
  <div *ngIf="eventType === 'videoTag'">
    <label>Video Tag:</label>
    <strong>{{ videoTag }}</strong>
    <ul *ngIf="!videoTag">
      <li><strong>d:</strong>Low break-out under pressure</li>
      <li
        ><strong>f:</strong>Defensive zone board sector break-out under
        pressure</li
      >
      <li><strong>b:</strong>Defenseman offensive blue line puck management</li>
      <li><strong>z:</strong>Controlled offensive zone entry</li>
    </ul>
  </div>
  <div *ngIf="eventType === 'highlight'">
    <label>Highlight Type:</label>
    <strong>{{ highlightType }}</strong>
    <ul *ngIf="!highlightType" style="columns: 2">
      <li>b<strong>e</strong>nch</li>
      <li><strong>b</strong>looper</li>
      <li><strong>c</strong>lose up</li>
      <li>c<strong>o</strong>mpilation</li>
      <li><strong>g</strong>oal</li>
      <li>h<strong>i</strong>ghlight play</li>
      <li><strong>h</strong>it</li>
      <li><strong>p</strong>enalty box</li>
      <li>pena<strong>l</strong>ty shot</li>
      <li>s<strong>a</strong>ve</li>
      <li><strong>s</strong>hot</li>
      <li>sta<strong>n</strong>ds</li>
      <li><strong>t</strong>itles</li>
    </ul>
    <div
      *ngIf="
        highlightType &&
        !(
          highlightType === 'goal' ||
          highlightType === 'save' ||
          highlightType === 'penalty_shot'
        )
      "
    >
      <label>Playback:</label>
      <strong>{{ highlightPlayback }}</strong>
      <ul *ngIf="!highlightPlayback">
        <li><strong>n</strong>ormal</li>
        <li><strong>s</strong>low motion</li>
      </ul>
    </div>
    <div
      *ngIf="
        (highlightPlayback && highlightType && !(highlightType === 'titles')) ||
        (highlightType &&
          (highlightType === 'goal' ||
            highlightType === 'save' ||
            highlightType === 'penalty_shot'))
      "
    >
      <label>Rating:</label>
      <app-star-rating
        style="float: right"
        [starCount]="5"
        [color]="'primary'"
        [ngModel]="highlightRating"
        (ngModelChange)="ratingChange($event)"
      ></app-star-rating>
    </div>
  </div>
</div>
