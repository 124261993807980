import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Game } from '../../domain/game';

@Component({
  selector: 'app-game-navigation',
  templateUrl: './game-navigation.component.html',
  styleUrls: ['./game-navigation.component.css']
})
export class GameNavigationComponent {
  @Input()
  game: Game;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  get currentView() {
    const path = this.activatedRoute.snapshot.url[0]?.path;
    if (!path) {
      return 'setup';
    }
    return path;
  }

  async viewChange(view: 'setup' | 'events' | 'time_on_ice') {
    if (view === 'setup') {
      await this.router.navigate(['games', this.game._id]);
    } else if (view === 'events') {
      await this.router.navigate(['games', this.game._id, 'events']);
    } else if (view === 'time_on_ice') {
      await this.router.navigate(['games', this.game._id, 'time-on-ice']);
    } else if (view === 'timeline') {
      await this.router.navigate(['games', this.game._id, 'timeline']);
    }
  }
  async openVideoPlayerWindow() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/games', this.game._id, 'video-player'])
    );
    const hasMultiScreen = (window.screen as any).isExtended;
    const supportsMultiScreenApi = 'getScreenDetails' in window;
    if (!hasMultiScreen || !supportsMultiScreenApi) {
      window.open(url, '_blank');
      return;
    }

    const screenDetails = await (window as any).getScreenDetails();
    const screen = screenDetails.screens.find(
      (s) => s.label !== screenDetails.currentScreen.label
    );
    window.open(
      url,
      '_blank',
      `left=${screen.availLeft},top=${screen.availTop},width=${screen.availWidth},height=${screen.availHeight}`
    );
  }
}
