import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BroadcastService, BroadcastAction } from '../broadcast.service';
import { PlayerService } from '../services/player.service';
import { CharCodes } from '../domain/char-codes';
import { DataSet, Game } from '../domain/game';
import {
  EventType,
  GamePeriod,
  HighlightPlayback,
  HighlightRating,
  HighlightType,
  InterruptionType,
  PassOutcome,
  PassType,
  PenaltyType,
  ShotOutcome,
  ShotScenario,
  ShotType,
  StrengthState,
  TeamFaceOffOutcome,
  VideoTagType
} from '../domain/game-event';
import { Team } from '../domain/team';
import { AlertService } from '../services/alert.service';
import { GameService } from '../services/game.service';
import { MatDialog } from '@angular/material/dialog';
import { ShotImpactDialogComponent } from '../game-events/shot-impact-dialog.component';
import { StrengthStateService } from '../services/strength-state.service';
import {
  selectDeflectorPlayerNumber,
  selectEventType,
  selectFaceOffOpponentPlayerNumber,
  selectFouledPlayerNumber,
  selectHas_net_traffic,
  selectHas_screen,
  selectHighlightPlayback,
  selectHighlightRating,
  selectHighlightType,
  selectInterruption_type,
  selectIs_blocked,
  selectNetImpact,
  selectNetTrafficCauserPlayerNumber,
  selectPassOutcome,
  selectPassReceiverPlayerNumber,
  selectPassType,
  selectPenaltyId,
  selectPenaltyType,
  selectPeriod,
  selectPlayerNumber,
  selectScreenerPlayerNumber,
  selectShotBlockerPlayerNumber,
  selectShotOutcome,
  selectShotScenario,
  selectShotType,
  selectStrengthState,
  selectTeam,
  selectTeamFaceOffOutcome,
  selectVideoTag,
  select_id
} from '../state/reducers/game-event.reducer';
import { Store } from '@ngrx/store';

import {
  deflectorPlayerNumberChange,
  eventTypeChange,
  faceoffOpponentPlayerNumberChange,
  fouledPlayerNumberChange,
  hasNetTrafficChange,
  hasScreenChange,
  highlightPlaybackChange,
  highlightRatingChange,
  highlightTypeChange,
  interruptionTypeChange,
  isBlockedChange,
  netImpactChange,
  netTrafficCauserPlayerNumberChange,
  passOutcomeChange,
  passReceiverPlayerNumberChange,
  passTypeChange,
  penaltyDurationChange,
  penaltyTypeChange,
  periodChange,
  playerNumberChange,
  screenerPlayerNumberChange,
  shotBlockerPlayerNumberChange,
  shotOutcomeChange,
  shotScenarioChange,
  shotTypeChange,
  strengthStateChange,
  teamChange,
  teamFaceoffOutcomeChange,
  videoTagChange
} from '../state/actions/game-event.action';
import { GlobalState } from '../state/reducers';
import ObjectId from 'bson-objectid';

@Component({
  selector: 'app-video-keyboard-actions',
  templateUrl: './video-keyboard-actions.component.html',
  styleUrls: ['./video-keyboard-actions.component.css']
})
export class VideoKeyboardActionsComponent implements OnInit, OnDestroy {
  private componentDestroyed$: Subject<void> = new Subject();
  _id: string;
  period = '';
  team: string;
  whichteam: string;
  eventType: EventType;
  interruptionType: InterruptionType;
  videoTag: VideoTagType;
  shotOutcome: ShotOutcome;
  shotScenario: ShotScenario;
  shotType: ShotType;
  is_blocked: string;
  has_net_traffic: string;
  has_screen: string;
  teamFaceOffOutcome: TeamFaceOffOutcome;
  passType: PassType;
  passOutcome: PassOutcome;
  playerNumberString = '';
  player = null;
  playerShoots = 'left';
  playerPosition = '';
  passReceiverPlayerNumberString = '';
  passReceiverPlayer = null;
  shotBlockerPlayerNumberString = '';
  shotBlockerPlayer = null;
  deflectorPlayerNumberString = '';
  deflectorPlayer = null;
  screenerPlayerNumberString = '';
  screenerPlayer = null;
  netTrafficCauserPlayerNumberString = '';
  netTrafficCauserPlayer = null;
  faceOffOpponentPlayerNumberString = '';
  faceOffOpponentPlayer = null;
  fouledPlayerNumberString = '';
  fouledPlayer = null;
  netImpactX: number;
  netImpactY: number;
  highlightType: HighlightType = null;
  highlightPlayback: HighlightPlayback = null;
  highlightRating: HighlightRating = null;
  strengthState: StrengthState;
  penaltyType: PenaltyType;
  penaltyId: ObjectId;

  players: any[] = [];

  @Input()
  game: Game;

  @Output()
  save = new EventEmitter<void>();

  @Output()
  keyTyped = new EventEmitter<void>();

  constructor(
    private broadcast: BroadcastService,
    private alertService: AlertService,
    private gameService: GameService,
    private playerService: PlayerService,
    private strengthStateService: StrengthStateService,
    private dialog: MatDialog,
    private store: Store<GlobalState>
  ) {}

  async ngOnInit() {
    await this.loadPlayers();
    this.subscribeToReduxEvents();
    this.broadcast
      .listen()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((message) => {
        switch (message.data.type) {
          case BroadcastAction.ResetState:
            this.resetHotKeys();
            break;
          case BroadcastAction.SaveComplete:
            this.handleSavingCompleted();
            break;
          case BroadcastAction.SaveFailed:
            this.handleSavingFailed(message.data.error);
            break;
        }
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  subscribeToReduxEvents() {
    this.store
      .select(select_id)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((_id) => (this._id = _id));
    this.store
      .select(selectEventType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.eventType = newValue));
    this.store
      .select(selectTeam)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.team = newValue));
    this.store
      .select(selectInterruption_type)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.interruptionType = newValue));
    this.store
      .select(selectVideoTag)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.videoTag = newValue));
    this.store
      .select(selectShotOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.shotOutcome = newValue));
    this.store
      .select(selectNetImpact)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.netImpactX = newValue?.netImpactX));
    this.store
      .select(selectNetImpact)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.netImpactY = newValue?.netImpactY));
    this.store
      .select(selectShotScenario)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.shotScenario = newValue));
    this.store
      .select(selectShotType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.shotType = newValue));
    this.store
      .select(selectIs_blocked)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.is_blocked = newValue));
    this.store
      .select(selectHas_net_traffic)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.has_net_traffic = newValue));
    this.store
      .select(selectHas_screen)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.has_screen = newValue));
    this.store
      .select(selectTeamFaceOffOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.teamFaceOffOutcome = newValue));
    this.store
      .select(selectPassType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.passType = newValue));
    this.store
      .select(selectPassOutcome)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.passOutcome = newValue));
    this.store
      .select(selectPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.player = newValue));
    this.store
      .select(selectPassReceiverPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.passReceiverPlayer = newValue));
    this.store
      .select(selectShotBlockerPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.shotBlockerPlayer = newValue));
    this.store
      .select(selectDeflectorPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.deflectorPlayer = newValue));
    this.store
      .select(selectScreenerPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.screenerPlayer = newValue));
    this.store
      .select(selectNetTrafficCauserPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.netTrafficCauserPlayer = newValue));
    this.store
      .select(selectFaceOffOpponentPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.faceOffOpponentPlayer = newValue));
    this.store
      .select(selectFouledPlayerNumber)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.fouledPlayer = newValue));
    this.store
      .select(selectPeriod)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => {
        this.period = newValue;
      });
    this.store
      .select(selectHighlightType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.highlightType = newValue));
    this.store
      .select(selectHighlightRating)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.highlightRating = newValue));
    this.store
      .select(selectHighlightPlayback)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.highlightPlayback = newValue));
    this.store
      .select(selectStrengthState)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.strengthState = newValue));
    this.store
      .select(selectPenaltyType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.penaltyType = newValue));
    this.store
      .select(selectPenaltyId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newValue) => (this.penaltyId = newValue));
  }

  private async loadPlayers() {
    if (!this.game) {
      return;
    }
    const homeTeamPlayers = await this.playerService
      .getPlayersByGameLineup(
        this.game._id,
        this.game.homeTeamId,
        this.game.idType
      )
      .toPromise();
    const awayTeamPlayers = await this.playerService
      .getPlayersByGameLineup(
        this.game._id,
        this.game.awayTeamId,
        this.game.idType
      )
      .toPromise();
    this.players = [...homeTeamPlayers, ...awayTeamPlayers];
  }

  @HostListener('window:keydown', ['$event'])
  hotkeys(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.escape_key_code) {
      this.handleResetHotKey();
    }

    const eventType = this.eventType;
    if (this.isPassEventTypeHotKey(event.keyCode, event.key, eventType)) {
      this.handlePassEventTypeHotKey(event, eventType);
    } else if (
      this.isShotEventTypeHotKey(event.keyCode, event.key, eventType)
    ) {
      this.handleShotEventTypeHotKey(event, eventType);
    } else if (this.isVideoTagEventType(event.keyCode, event.key, eventType)) {
      this.handleVideoTagEventType(event, eventType);
    } else if (
      this.isFaceOffEventTypeHotKey(event.keyCode, event.key, eventType)
    ) {
      this.handleFaceOffEventTypeHotKey(event, eventType);
    } else if (
      this.isPenaltyEventTypeHotKey(event.keyCode, event.key, eventType)
    ) {
      this.handlePenaltyEventTypeHotKey(event, eventType);
    } else if (
      this.isHighlightEventTypeHotKey(event.keyCode, event.key, eventType)
    ) {
      this.handleHighlightEventTypeHotKey(event, eventType);
    } else if (this.isInterruptionEventTypePeriod(event.keyCode, eventType)) {
      this.handleInterruptionEventTypePeriod(event, eventType);
    } else if (
      this.isInterruptionEventTypeNotPeriod(event.keyCode, eventType)
    ) {
      this.handleInterruptionEventTypeNotPeriod(event, eventType);
    } else if (this.isPuckPossessionEventType(event.keyCode, eventType)) {
      this.handlePuckPossessionEventType(event, eventType);
    } else if (this.isOddMenRushEventType(event.keyCode, eventType)) {
      this.handleOddMenRushEventType(event, eventType);
    } else if (this.isTeamHotKey(event.keyCode)) {
      this.handleTeamHotKey(event, eventType);
    } else if (this.isNumberHotKey(event.key)) {
      this.handlePlayerNumberHotKey(event);
    } else if (this.isSaveHotKey(event.keyCode)) {
      console.log('handleSaveHotKey', event);
      this.handleSaveHotKey();
    }

    if (eventType) {
      this.keyTyped.emit();
    }
  }

  private isNumberHotKey(key): boolean {
    return !(isNaN(key) || key === null || key === ' ');
  }

  private handlePlayerNumberHotKey(event: KeyboardEvent) {
    if (this.playerNumberString.length <= 1) {
      this.playerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(this.playerNumberString, false);
      if (player) {
        this.player = player;
        this.store.dispatch(playerNumberChange({ playerNumber: this.player }));

        this.playerShoots = this.getPlayerShoots(player);
        this.playerPosition = this.getPlayerPosition(player);
      }
    } else if (this.playerNumberString.length > 1) {
      this.playerNumberString = '';
      this.player = null;
      this.playerShoots = null;
      this.playerPosition = null;
    }
  }

  private getPlayerShoots(player: string) {
    if (!this.team) {
      return null;
    }
    const playerId = this.game.getPlayerIdByJerseyNumber(
      this.team === this.game.homeTeam,
      player
    );
    const playerObj = this.players.find((p) => p.id === playerId);
    return playerObj ? playerObj.shoots : null;
  }

  private getPlayerPosition(player: string) {
    if (!this.team) {
      return null;
    }
    const playerId = this.game.getPlayerIdByJerseyNumber(
      this.team === this.game.homeTeam,
      player
    );
    const playerObj = this.players.find((p) => p.id === playerId);
    return playerObj?.position?.substring(0, 1);
  }

  private lookupPlayer(playerNumber: string, lookInOpponentTeam: boolean) {
    let player = null;
    const lookupInAwayTeam =
      (this.whichteam === 'homeTeam' && lookInOpponentTeam) ||
      (this.whichteam === 'awayTeam' && !lookInOpponentTeam);
    if (lookupInAwayTeam) {
      player = this.gameService.getAwayTeamPlayerByJerseyNumber(
        this.game,
        playerNumber
      );
    } else {
      player = this.gameService.getHomeTeamPlayerByJerseyNumber(
        this.game,
        playerNumber
      );
    }
    return player;
  }

  private isPassEventTypeHotKey(keyCode, key, eventType) {
    return (
      (eventType == null && keyCode === CharCodes.p_key_code) ||
      (eventType === 'pass' &&
        (keyCode === CharCodes.p_key_code ||
          keyCode === CharCodes.c_key_code ||
          keyCode === CharCodes.s_key_code ||
          keyCode === CharCodes.r_key_code)) ||
      (eventType === 'pass' &&
        (this.passType === 'pass' || this.passType === 'shot_pass') &&
        (keyCode === CharCodes.c_key_code ||
          keyCode === CharCodes.i_key_code ||
          this.isNumberHotKey(key))) ||
      (eventType === 'pass' &&
        this.passType === 'chip' &&
        (keyCode === CharCodes.x_key_code ||
          keyCode === CharCodes.b_key_code ||
          keyCode === CharCodes.i_key_code ||
          keyCode === CharCodes.f_key_code ||
          this.isNumberHotKey(key))) ||
      (eventType === 'pass' &&
        this.passType === 'rim' &&
        (keyCode === CharCodes.x_key_code ||
          keyCode === CharCodes.b_key_code ||
          keyCode === CharCodes.f_key_code ||
          this.isNumberHotKey(key)))
    );
  }

  private handlePassEventTypeHotKey(event: KeyboardEvent, eventType) {
    if (eventType !== 'pass') {
      // newly entered pass
      this.setPassEventType();
    } else {
      // handle pass details
      this.handlePassDetails(event);
    }
  }

  private handlePassDetails(event: KeyboardEvent) {
    if (this.passType == null) {
      this.handleNewlyEnteredPassType(event);
    } else {
      if (this.passType === 'pass' || this.passType === 'shot_pass') {
        if (this.isNumberHotKey(event.key)) {
          this.handlePlayerNumberHotKeyForPassReceiver(event);
        } else {
          this.handlePassOutcome(event);
        }
      }
    }
  }

  private handlePlayerNumberHotKeyForPassReceiver(event: KeyboardEvent) {
    if (this.passReceiverPlayerNumberString.length <= 1) {
      this.passReceiverPlayerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(
        this.passReceiverPlayerNumberString,
        false
      );
      if (player) {
        this.passReceiverPlayer = player;
        this.store.dispatch(
          passReceiverPlayerNumberChange({
            passReceiverPlayerNumber: this.passReceiverPlayer
          })
        );
      }
    } else if (this.passReceiverPlayerNumberString.length > 1) {
      this.passReceiverPlayerNumberString = '';
      this.passReceiverPlayer = null;
    }
  }

  private handleNewlyEnteredPassType(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.c_key_code) {
      this.passType = 'chip';
    } else if (event.keyCode === CharCodes.r_key_code) {
      this.passType = 'rim';
    } else if (event.keyCode === CharCodes.p_key_code) {
      this.passType = 'pass';
    } else if (event.keyCode === CharCodes.s_key_code) {
      this.passType = 'shot_pass';
    }
    this.store.dispatch(passTypeChange({ passType: this.passType }));
  }

  private handlePassOutcome(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.c_key_code) {
      this.passOutcome = 'complete';
    } else if (event.keyCode === CharCodes.i_key_code) {
      this.passOutcome = 'incomplete';
    }
    this.store.dispatch(passOutcomeChange({ passOutcome: this.passOutcome }));
  }

  private setPassEventType() {
    this.eventType = 'pass';
    this.store.dispatch(eventTypeChange({ eventType: 'pass' }));
  }

  private isShotEventTypeHotKey(keyCode, key, eventType) {
    return (
      (eventType == null && keyCode === CharCodes.s_key_code) ||
      (eventType === 'shot' &&
        (this.isNumberHotKey(key) ||
          this.isShotOutcomeHotKey(keyCode, eventType) ||
          this.isShotScenarioHotKey(keyCode, eventType) ||
          this.isShotTypeHotKey(keyCode, eventType) ||
          this.isDeflectionTypeHotKey(keyCode, eventType) ||
          this.hasNetTrafficTypeHotKey(keyCode, eventType) ||
          this.hasScreenTypeHotKey(keyCode, eventType))) ||
      (eventType === 'shot' &&
        (this.shotOutcome === 'blocked' ||
          this.has_net_traffic === '1' ||
          this.has_screen === '1') &&
        this.isNumberHotKey(key))
    );
  }

  private isShotOutcomeHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome == null &&
      (keyCode === CharCodes.m_key_code ||
        keyCode === CharCodes.b_key_code ||
        keyCode === CharCodes.i_key_code ||
        keyCode === CharCodes.g_key_code ||
        keyCode === CharCodes.o_key_code)
    );
  }

  private isShotScenarioHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome != null &&
      (keyCode === CharCodes.r_key_code ||
        keyCode === CharCodes.o_key_code ||
        keyCode === CharCodes.e_key_code ||
        keyCode === CharCodes.q_key_code)
    );
  }

  private isShotTypeHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome != null &&
      this.shotScenario != null &&
      (keyCode === CharCodes.w_key_code ||
        keyCode === CharCodes.s_key_code ||
        keyCode === CharCodes.d_key_code ||
        keyCode === CharCodes.b_key_code)
    );
  }

  private isDeflectionTypeHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome != null &&
      this.shotScenario != null &&
      this.shotType != null &&
      (keyCode === CharCodes.y_key_code || keyCode === CharCodes.n_key_code)
    );
  }

  private hasNetTrafficTypeHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome != null &&
      this.shotScenario != null &&
      this.shotType != null &&
      this.has_net_traffic == null &&
      (keyCode === CharCodes.y_key_code || keyCode === CharCodes.n_key_code)
    );
  }

  private hasScreenTypeHotKey(keyCode, eventType) {
    return (
      eventType === 'shot' &&
      this.shotOutcome != null &&
      this.shotScenario != null &&
      this.shotType != null &&
      this.has_net_traffic != null &&
      this.has_screen == null &&
      (keyCode === CharCodes.y_key_code || keyCode === CharCodes.n_key_code)
    );
  }

  private handleShotEventTypeHotKey(event: KeyboardEvent, eventType) {
    if (eventType !== 'shot') {
      this.store.dispatch(eventTypeChange({ eventType: 'shot' }));
    } else {
      this.handleShotDetails(event, eventType);
    }
  }

  private handleShotDetails(event: KeyboardEvent, eventType) {
    if (this.isNumberHotKey(event.key)) {
      if (
        this.shotOutcome === 'blocked' &&
        this.shotType !== 'deflection' &&
        (this.has_net_traffic === undefined || this.has_net_traffic === '0')
      ) {
        this.handlePlayerNumberHotKeyForShotBlocker(event);
      } else if (
        this.shotType === 'deflection' &&
        (this.has_net_traffic === undefined || this.has_net_traffic === '0')
      ) {
        this.handlePlayerNumberHotKeyForDeflector(event);
      } else if (
        this.has_net_traffic === '1' &&
        this.has_screen === undefined
      ) {
        this.handlePlayerNumberHotKeyForNetTrafficCauser(event);
      } else if (this.has_screen === '1') {
        this.handlePlayerNumberHotKeyForScreener(event);
      } else {
        this.handlePlayerNumberHotKey(event);
      }
    } else if (this.isShotOutcomeHotKey(event.keyCode, eventType)) {
      this.handleShotOutcomeHotKey(event);
    } else if (this.isShotScenarioHotKey(event.keyCode, eventType)) {
      this.handleShotScenarioHotKey(event);
    } else if (this.isShotTypeHotKey(event.keyCode, eventType)) {
      this.handleShotTypeHotKey(event);
    } else if (this.hasNetTrafficTypeHotKey(event.keyCode, eventType)) {
      this.handleShotNetTrafficHotKey(event);
    } else if (this.hasScreenTypeHotKey(event.keyCode, eventType)) {
      this.handleShotScreenHotKey(event);
    }
  }

  private handleShotOutcomeHotKey(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.b_key_code) {
      this.shotOutcome = 'blocked';
      this.store.dispatch(isBlockedChange({ is_blocked: this.is_blocked }));
    } else if (event.keyCode === CharCodes.m_key_code) {
      this.shotOutcome = 'miss';
    } else if (event.keyCode === CharCodes.g_key_code) {
      this.shotOutcome = 'goal';
    } else if (event.keyCode === CharCodes.o_key_code) {
      this.shotOutcome = 'on_goal';
    } else if (event.keyCode === CharCodes.i_key_code) {
      this.shotOutcome = 'iron';
    }
    this.store.dispatch(shotOutcomeChange({ shotOutcome: this.shotOutcome }));

    if (
      !this.game.isLiveCollection &&
      this.game.dataSet === DataSet.FULL &&
      ['goal', 'on_goal', 'iron'].includes(this.shotOutcome)
    ) {
      this.showImpactDialog();
    }
  }

  private handlePlayerNumberHotKeyForShotBlocker(event: KeyboardEvent) {
    if (this.shotBlockerPlayerNumberString.length <= 1) {
      this.shotBlockerPlayerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(
        this.shotBlockerPlayerNumberString,
        true
      );
      if (player) {
        this.shotBlockerPlayer = player;
        this.store.dispatch(
          shotBlockerPlayerNumberChange({
            shotBlockerPlayerNumber: this.shotBlockerPlayer
          })
        );
      }
    } else if (this.shotBlockerPlayerNumberString.length > 1) {
      this.shotBlockerPlayerNumberString = '';
      this.shotBlockerPlayer = null;
    }
  }

  private handlePlayerNumberHotKeyForScreener(event: KeyboardEvent) {
    if (this.screenerPlayerNumberString.length <= 1) {
      this.screenerPlayerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(this.screenerPlayerNumberString, false);
      if (player) {
        this.screenerPlayer = player;
        this.store.dispatch(
          screenerPlayerNumberChange({
            screenerPlayerNumber: this.screenerPlayer
          })
        );
      }
    } else if (this.screenerPlayerNumberString.length > 1) {
      this.screenerPlayerNumberString = '';
      this.screenerPlayer = null;
    }
  }

  private handlePlayerNumberHotKeyForNetTrafficCauser(event: KeyboardEvent) {
    if (this.netTrafficCauserPlayerNumberString.length <= 1) {
      this.netTrafficCauserPlayerNumberString += String.fromCharCode(
        event.keyCode
      );
      const player = this.lookupPlayer(
        this.netTrafficCauserPlayerNumberString,
        false
      );
      if (player) {
        this.netTrafficCauserPlayer = player;
        this.store.dispatch(
          netTrafficCauserPlayerNumberChange({
            netTrafficCauserPlayerNumber: this.netTrafficCauserPlayer
          })
        );
      }
    } else if (this.netTrafficCauserPlayerNumberString.length > 1) {
      this.netTrafficCauserPlayerNumberString = '';
      this.netTrafficCauserPlayer = null;
    }
  }

  private handlePlayerNumberHotKeyForDeflector(event: KeyboardEvent) {
    if (this.deflectorPlayerNumberString.length <= 1) {
      this.deflectorPlayerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(this.deflectorPlayerNumberString, false);
      if (player) {
        this.deflectorPlayer = player;
        this.store.dispatch(
          deflectorPlayerNumberChange({
            deflectorPlayerNumber: this.deflectorPlayer
          })
        );
      }
    } else if (this.deflectorPlayerNumberString.length > 1) {
      this.deflectorPlayerNumberString = '';
      this.deflectorPlayer = null;
    }
  }

  private handleShotScenarioHotKey(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.r_key_code) {
      this.shotScenario = 'regular';
    } else if (event.keyCode === CharCodes.q_key_code) {
      this.shotScenario = 'quick_release';
    } else if (event.keyCode === CharCodes.e_key_code) {
      this.shotScenario = 'rebound';
    } else if (event.keyCode === CharCodes.o_key_code) {
      this.shotScenario = 'one_timer';
    }
    this.store.dispatch(
      shotScenarioChange({ shotScenario: this.shotScenario })
    );
  }

  private handleShotTypeHotKey(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.w_key_code) {
      this.shotType = 'wrist_shot';
    } else if (event.keyCode === CharCodes.s_key_code) {
      this.shotType = 'slap_shot';
    } else if (event.keyCode === CharCodes.b_key_code) {
      this.shotType = 'backhand_shot';
    } else if (event.keyCode === CharCodes.d_key_code) {
      this.shotType = 'deflection';
    }
    this.store.dispatch(shotTypeChange({ shotType: this.shotType }));
  }

  private handleShotNetTrafficHotKey(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.y_key_code) {
      this.has_net_traffic = '1';
    } else if (event.keyCode === CharCodes.n_key_code) {
      this.has_net_traffic = '0';
    }
    this.store.dispatch(
      hasNetTrafficChange({ has_net_traffic: this.has_net_traffic })
    );
  }

  private handleShotScreenHotKey(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.y_key_code) {
      this.has_screen = '1';
    } else if (event.keyCode === CharCodes.n_key_code) {
      this.has_screen = '0';
    }
    this.store.dispatch(hasScreenChange({ has_screen: this.has_screen }));
  }

  private isFaceOffEventTypeHotKey(keyCode, key, eventType) {
    return (
      (eventType == null && keyCode === CharCodes.f_key_code) ||
      (eventType === 'face_off' && this.isFaceOffDetailHotKey(keyCode, key))
    );
  }

  private isFaceOffDetailHotKey(keyCode, key) {
    return (
      this.isNumberHotKey(key) ||
      keyCode === CharCodes.w_key_code ||
      keyCode === CharCodes.l_key_code ||
      keyCode === CharCodes.n_key_code
    );
  }

  private handleFaceOffEventTypeHotKey(event: KeyboardEvent, eventType) {
    if (eventType !== 'face_off') {
      this.store.dispatch(eventTypeChange({ eventType: 'face_off' }));
    } else {
      // handle face_off details
      this.handleFaceOffDetails(event);
    }
  }

  private handleFaceOffDetails(event: KeyboardEvent) {
    if (this.isNumberHotKey(event.key)) {
      if (this.teamFaceOffOutcome == null) {
        this.handlePlayerNumberHotKey(event);
      } else {
        this.handlePlayerNumberHotKeyForFaceOffOpponent(event);
      }
    } else if (this.isFaceOffDetailHotKey(event.keyCode, event.key)) {
      if (this.teamFaceOffOutcome == null) {
        if (event.keyCode === CharCodes.w_key_code) {
          this.teamFaceOffOutcome = 'win';
        } else if (event.keyCode === CharCodes.l_key_code) {
          this.teamFaceOffOutcome = 'lost';
        }
        this.store.dispatch(
          teamFaceoffOutcomeChange({
            teamFaceOffOutcome: this.teamFaceOffOutcome
          })
        );
      }
    }
  }

  private handlePlayerNumberHotKeyForFaceOffOpponent(event: KeyboardEvent) {
    if (this.faceOffOpponentPlayerNumberString.length <= 1) {
      this.faceOffOpponentPlayerNumberString += String.fromCharCode(
        event.keyCode
      );
      const player = this.lookupPlayer(
        this.faceOffOpponentPlayerNumberString,
        true
      );
      if (player) {
        this.faceOffOpponentPlayer = player;
        this.store.dispatch(
          faceoffOpponentPlayerNumberChange({
            faceOffOpponentPlayerNumber: this.faceOffOpponentPlayer
          })
        );
      }
    } else if (this.faceOffOpponentPlayerNumberString.length > 1) {
      this.faceOffOpponentPlayerNumberString = '';
      this.faceOffOpponentPlayer = null;
    }
  }

  private isPenaltyEventTypeHotKey(keyCode, key, eventType) {
    return (
      (keyCode === CharCodes.y_key_code && this.eventType !== 'interruption') ||
      (eventType === 'penalty' &&
        this.player !== null &&
        this.isNumberHotKey(key))
    );
  }

  private handlePenaltyEventTypeHotKey(event: KeyboardEvent, eventType) {
    if (eventType !== 'penalty') {
      if (this.game.isLiveDraftEvents) {
        this.handlePenaltyEventStartInLiveDraftGame();
      }
      this.store.dispatch(eventTypeChange({ eventType: 'penalty' }));
    } else if (this.isNumberHotKey(event.key)) {
      this.handlePlayerNumberHotKeyForFouledPlayer(event);
    }
  }

  private handlePlayerNumberHotKeyForFouledPlayer(event: KeyboardEvent) {
    if (this.fouledPlayerNumberString.length <= 1) {
      this.fouledPlayerNumberString += String.fromCharCode(event.keyCode);
      const player = this.lookupPlayer(this.fouledPlayerNumberString, true);
      if (player) {
        this.fouledPlayer = player;
        this.store.dispatch(
          fouledPlayerNumberChange({ fouledPlayerNumber: this.fouledPlayer })
        );
      }
    } else if (this.fouledPlayerNumberString.length > 1) {
      this.fouledPlayerNumberString = '';
      this.fouledPlayer = null;
    }
  }

  private isInterruptionEventTypeNotPeriod(
    keyCode: number,
    eventType: EventType
  ) {
    const interruptionTypeKeyCodes = new Set([
      CharCodes.g_key_code,
      CharCodes.i_key_code,
      CharCodes.k_key_code,
      CharCodes.o_key_code,
      CharCodes.p_key_code,
      CharCodes.u_key_code,
      CharCodes.y_key_code
    ]);

    return (
      (!eventType && keyCode === CharCodes.i_key_code) ||
      (eventType === 'interruption' && interruptionTypeKeyCodes.has(keyCode))
    );
  }

  private isPuckPossessionEventType(keyCode, eventType) {
    return keyCode === CharCodes.u_key_code;
  }

  private isOddMenRushEventType(keyCode, eventType) {
    return eventType == null && keyCode === CharCodes.o_key_code;
  }

  private isVideoTagEventType(keyCode, key, eventType) {
    return (
      keyCode === CharCodes.v_key_code ||
      (eventType === 'videoTag' &&
        (keyCode === CharCodes.d_key_code ||
          keyCode === CharCodes.f_key_code ||
          keyCode === CharCodes.b_key_code ||
          keyCode === CharCodes.z_key_code)) ||
      (eventType === 'videoTag' &&
        this.videoTag != null &&
        this.isNumberHotKey(key))
    );
  }

  private handleInterruptionEventTypeNotPeriod(event, eventType: EventType) {
    if (eventType !== 'interruption') {
      this.store.dispatch(eventTypeChange({ eventType: 'interruption' }));
    } else if (this.interruptionType == null) {
      if (event.keyCode === CharCodes.g_key_code) {
        this.interruptionType = 'goal';
      } else if (event.keyCode === CharCodes.i_key_code) {
        this.interruptionType = 'icing';
      } else if (event.keyCode === CharCodes.k_key_code) {
        this.interruptionType = 'puck_blocked_by_goalkeeper';
      } else if (event.keyCode === CharCodes.o_key_code) {
        this.interruptionType = 'offside';
      } else if (event.keyCode === CharCodes.p_key_code) {
        this.interruptionType = 'puck_out';
      } else if (event.keyCode === CharCodes.u_key_code) {
        this.interruptionType = 'unspecified';
      } else if (event.keyCode === CharCodes.y_key_code) {
        this.interruptionType = 'penalty';
      }

      this.store.dispatch(
        interruptionTypeChange({ interruption_type: this.interruptionType })
      );
    }
  }

  private handleVideoTagEventType(event: KeyboardEvent, eventType) {
    if (eventType !== 'videoTag') {
      this.store.dispatch(eventTypeChange({ eventType: 'videoTag' }));
    } else if (this.videoTag == null) {
      if (event.keyCode === CharCodes.d_key_code) {
        this.videoTag = 'low_break_out_under_pressure';
      } else if (event.keyCode === CharCodes.f_key_code) {
        this.videoTag = 'board_sector_break_out_under_pressure';
      } else if (event.keyCode === CharCodes.b_key_code) {
        this.videoTag = 'd_offensive_blue_line_puck_management';
        this.checkIfPlayerMatchesDefensemenVideoTag();
      } else if (event.keyCode === CharCodes.z_key_code) {
        this.videoTag = 'controlled_offensive_zone_entry';
      }
      this.store.dispatch(videoTagChange({ videoTag: this.videoTag }));
    }
  }

  private checkIfPlayerMatchesDefensemenVideoTag() {
    if (this.whichteam === 'homeTeam') {
      if (
        !this.gameService.getHomeTeamDefensemen(this.game).includes(this.player)
      ) {
        this.alertService.showWarning(
          `Player '${this.player}' is not part of the home team defensemen!`
        );
      }
    } else if (this.whichteam === 'awayTeam') {
      if (
        !this.gameService.getAwayTeamDefensemen(this.game).includes(this.player)
      ) {
        this.alertService.showWarning(
          `Player '${this.player}' is not part of the away team defensemen!`
        );
      }
    }
  }

  private handlePuckPossessionEventType(event: KeyboardEvent, eventType) {
    if (eventType !== 'puckPossession') {
      this.setPuckPossessionEventType();
    }
  }

  private setPuckPossessionEventType() {
    this.eventType = 'puckPossession';
    this.store.dispatch(eventTypeChange({ eventType: 'puckPossession' }));
  }

  private handleOddMenRushEventType(event, eventType) {
    if (eventType !== 'oddMenRush') {
      this.setOddMenRushEventType();
    }
  }

  private setOddMenRushEventType() {
    this.eventType = 'oddMenRush';
    this.store.dispatch(eventTypeChange({ eventType: 'oddMenRush' }));
  }

  private isTeamHotKey(keyCode) {
    return (
      keyCode === CharCodes.h_key_code ||
      keyCode === CharCodes.a_key_code ||
      keyCode === CharCodes.n_key_code
    );
  }

  private handleTeamHotKey(event: KeyboardEvent, eventType: EventType) {
    if (event.keyCode === CharCodes.h_key_code) {
      this.team = this.game.homeTeam;
      this.whichteam = 'homeTeam';
    } else if (event.keyCode === CharCodes.a_key_code) {
      this.team = this.game.awayTeam;
      this.whichteam = 'awayTeam';
    } else if (event.keyCode === CharCodes.n_key_code) {
      this.team = Team.NEUTRAL_TEAM;
      this.whichteam = Team.NEUTRAL_TEAM;
    }

    if (this.game.isLiveDraftEvents && eventType === 'penalty') {
      this.handlePenaltyStrengthState();
    }
    this.store.dispatch(teamChange({ team: this.team }));
  }

  private isSaveHotKey(keyCode) {
    return keyCode === CharCodes.enter_key_code;
  }

  private handleSaveHotKey() {
    this.broadcast.postMessage({ type: BroadcastAction.Save });
  }

  private isHighlightEventTypeHotKey(keyCode, key, eventType) {
    return (
      this.game.isHighlightCollection &&
      ((!eventType && keyCode === CharCodes.h_key_code) ||
        (eventType === 'highlight' &&
          this.team &&
          (keyCode === CharCodes.h_key_code ||
            keyCode === CharCodes.a_key_code) &&
          !this.highlightType) ||
        (eventType === 'highlight' &&
          !this.highlightType &&
          (keyCode === CharCodes.g_key_code ||
            keyCode === CharCodes.s_key_code ||
            keyCode === CharCodes.b_key_code ||
            keyCode === CharCodes.i_key_code ||
            keyCode === CharCodes.c_key_code ||
            keyCode === CharCodes.t_key_code ||
            keyCode === CharCodes.e_key_code ||
            keyCode === CharCodes.n_key_code ||
            keyCode === CharCodes.p_key_code ||
            keyCode === CharCodes.o_key_code ||
            keyCode === CharCodes.l_key_code)) ||
        (this.highlightType &&
          !(
            this.highlightType === 'goal' ||
            this.highlightType === 'save' ||
            this.highlightType === 'penalty_shot'
          ) &&
          !this.highlightPlayback &&
          (keyCode === CharCodes.n_key_code ||
            keyCode === CharCodes.s_key_code)) ||
        (this.highlightType &&
          ((this.highlightPlayback && !(this.highlightType === 'titles')) ||
            this.highlightType === 'goal' ||
            this.highlightType === 'save' ||
            this.highlightType === 'penalty_shot') &&
          this.isNumberHotKey(key)))
    );
  }

  private handleHighlightEventTypeHotKey(event: KeyboardEvent, eventType) {
    if (eventType !== 'highlight') {
      // newly entered highlight
      this.setHighlightEventType();
    } else {
      // handle highlight details
      this.handleHighlightDetails(event);
    }
  }

  private setHighlightEventType() {
    this.eventType = 'highlight';
    this.store.dispatch(eventTypeChange({ eventType: 'highlight' }));
  }

  private handleHighlightDetails(event: KeyboardEvent) {
    if (!this.highlightType) {
      this.handleNewlyEnteredHighlightType(event);
    } else if (
      !this.highlightPlayback &&
      !(
        this.highlightType === 'goal' ||
        this.highlightType === 'save' ||
        this.highlightType === 'penalty_shot'
      )
    ) {
      this.handleNewlyEnteredHighlightPlayback(event);
    } else {
      if (this.isNumberHotKey(event.key)) {
        this.handleRatingNumberHotKeyForHighlight(event);
      }
    }
  }

  private handleNewlyEnteredHighlightType(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.g_key_code) {
      this.highlightType = 'goal';
    } else if (event.keyCode === CharCodes.s_key_code) {
      this.highlightType = 'shot';
    } else if (event.keyCode === CharCodes.a_key_code) {
      this.highlightType = 'save';
    } else if (event.keyCode === CharCodes.h_key_code) {
      this.highlightType = 'hit';
    } else if (event.keyCode === CharCodes.b_key_code) {
      this.highlightType = 'blooper';
    } else if (event.keyCode === CharCodes.i_key_code) {
      this.highlightType = 'highlight_play';
    } else if (event.keyCode === CharCodes.c_key_code) {
      this.highlightType = 'close_up';
    } else if (event.keyCode === CharCodes.t_key_code) {
      this.highlightType = 'titles';
    } else if (event.keyCode === CharCodes.e_key_code) {
      this.highlightType = 'bench';
    } else if (event.keyCode === CharCodes.n_key_code) {
      this.highlightType = 'stands';
    } else if (event.keyCode === CharCodes.p_key_code) {
      this.highlightType = 'penalty_box';
    } else if (event.keyCode === CharCodes.o_key_code) {
      this.highlightType = 'compilation';
    } else if (event.keyCode === CharCodes.l_key_code) {
      this.highlightType = 'penalty_shot';
    }

    this.store.dispatch(
      highlightTypeChange({ highlightType: this.highlightType })
    );
  }

  private handleNewlyEnteredHighlightPlayback(event: KeyboardEvent) {
    if (event.keyCode === CharCodes.n_key_code) {
      this.highlightPlayback = 'normal';
    } else if (event.keyCode === CharCodes.s_key_code) {
      this.highlightPlayback = 'slow_motion';
    }

    this.store.dispatch(
      highlightPlaybackChange({ highlightPlayback: this.highlightPlayback })
    );
  }

  private handleRatingNumberHotKeyForHighlight(event: KeyboardEvent) {
    const rating = parseInt(
      String.fromCharCode(event.keyCode),
      10
    ) as HighlightRating;
    if (rating > 0 && rating < 6) {
      this.highlightRating = rating;
      this.store.dispatch(
        highlightRatingChange({ highlightRating: this.highlightRating })
      );
    }
  }

  ratingChange(value) {
    this.highlightRating = value === 0 ? null : value;
    this.store.dispatch(
      highlightRatingChange({ highlightRating: this.highlightRating })
    );
  }

  handleResetHotKey() {
    this.resetHotKeys();
    this.broadcast.postMessage({ type: BroadcastAction.ResetState });
  }

  private isInterruptionEventTypePeriod(keyCode, eventType) {
    return (
      keyCode === CharCodes.t_key_code ||
      keyCode === CharCodes.e_key_code ||
      (eventType === 'interruption' &&
        this.interruptionType === 'period_start' &&
        !this.isSaveHotKey(keyCode))
    );
  }

  private handleInterruptionEventTypePeriod(event: KeyboardEvent, eventType) {
    if (eventType !== 'interruption') {
      if (event.keyCode === CharCodes.t_key_code) {
        this.store.dispatch(
          interruptionTypeChange({ interruption_type: 'period_start' })
        );
      } else if (event.keyCode === CharCodes.e_key_code) {
        this.store.dispatch(
          interruptionTypeChange({ interruption_type: 'period_end' })
        );
      }
      setTimeout(
        () =>
          this.store.dispatch(eventTypeChange({ eventType: 'interruption' })),
        0
      );
    } else if (
      eventType === 'interruption' &&
      this.interruptionType === 'period_start'
    ) {
      const period = this.gameService.findPeriodByKeyCode(event.keyCode);
      if (period) {
        this.onPeriodChange(period as GamePeriod);
      }
    }
  }

  private resetHotKeys() {
    this.team = null;
    this.playerNumberString = '';
    this.passReceiverPlayerNumberString = '';
    this.shotBlockerPlayerNumberString = '';
    this.deflectorPlayerNumberString = '';
    this.screenerPlayerNumberString = '';
    this.netTrafficCauserPlayerNumberString = '';
    this.faceOffOpponentPlayerNumberString = '';
    this.fouledPlayerNumberString = '';
  }

  private handleSavingFailed(errorMessage: string) {
    this.alertService.showError('Saving event failed: ' + errorMessage);
  }

  private handleSavingCompleted() {
    this.alertService.showInfo('Saving event completed!');
  }

  get playerWarning() {
    return (
      this.eventType === 'face_off' && this.isNotForward(this.team, this.player)
    );
  }

  get faceOffOpponentPlayerWarning() {
    if (!this.game) {
      return false;
    }
    const opponentTeam =
      this.team === this.game.homeTeam
        ? this.game.awayTeam
        : this.game.homeTeam;
    return (
      this.eventType === 'face_off' &&
      this.isNotForward(opponentTeam, this.faceOffOpponentPlayer)
    );
  }

  get editMode() {
    return !!this._id;
  }

  private isNotForward(team: string, player: string) {
    if (!team || !player || !this.game) {
      return false;
    }
    return !this.game.getForwards(team).includes(player);
  }

  showImpactDialog() {
    const dialogRef = this.dialog.open(ShotImpactDialogComponent, {
      width: '320px',
      height: '350px',
      data: {
        netImpactX: null,
        netImpactY: null
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.netImpactX && result.netImpactY) {
        this.store.dispatch(
          netImpactChange({
            netImpact: {
              netImpactX: result.netImpactX,
              netImpactY: result.netImpactY
            }
          })
        );
      }
    });
  }

  requiresTeam() {
    return this.eventType && !['interruption'].includes(this.eventType);
  }

  requiresPlayer() {
    return (
      this.eventType &&
      !['interruption'].includes(this.eventType) &&
      !(
        ['shot', 'penalty'].includes(this.eventType) &&
        this.game.isLiveDraftEvents
      )
    );
  }

  onPeriodChange(period: GamePeriod) {
    this.period = period;
    this.store.dispatch(periodChange({ period }));
  }

  handlePenaltyStrengthState() {
    if (!this.penaltyType) {
      return;
    }
    const direction = this.penaltyType === 'start' ? -1 : 1;
    const result = this.strengthStateService.changeStrengthState(
      this.strengthState,
      this.game.homeTeam === this.team,
      direction
    );
    // Only emit if valid strength state
    if (result) {
      this.strengthState = result;
      this.store.dispatch(strengthStateChange({ strengthState: result }));
    }
  }

  handlePenaltyEventStartInLiveDraftGame() {
    this.penaltyType = 'start';
    this.store.dispatch(penaltyTypeChange({ penaltyType: 'start' }));
    this.store.dispatch(penaltyDurationChange({ penaltyDuration: '2' }));

    // If team is already selected calculate strength
    if (this.game.isLiveDraftEvents && this.team) {
      this.handlePenaltyStrengthState();
    }
  }
}
