import { League } from './league';

export class Team {
  public static NEUTRAL_TEAM = 'neutral';

  public id: string;
  public name: string;
  public shortName: string;
  public additionalLeagues?: League[];
  public league?: string;

  sihfId?: string;
  eliteProspectsId?: string;
  sportcontractId?: string;
  nhlId?: string;
  instatId?: string;

  constructor() {}
}
