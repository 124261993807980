<div id="main" *ngIf="videoUrl !== null">
  <div class="right">
    <mat-slide-toggle
      class="game-context-toggle"
      [color]="'primary'"
      [checked]="showGameContext"
      (change)="showGameContext = !showGameContext"
      matTooltip="Toggle Game Context"
    >
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="eventType && requiresIceRink(eventType)"
      class="ice-rink-toggle"
      [color]="'primary'"
      [checked]="showIceRink"
      (change)="showIceRink = !showIceRink"
      matTooltip="Toggle Ice Rink"
    >
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="
        eventType &&
        showIceRink &&
        requiresIceRink(eventType) &&
        game.playerTracks
      "
      class="player-position-toggle"
      [color]="'primary'"
      [checked]="showPlayerPosition"
      (change)="showPlayerPosition = !showPlayerPosition"
      matTooltip="Toggle Player Position"
    >
    </mat-slide-toggle>
    <div
      [ngClass]="screensize"
      class="ice-rink-wrapper"
      *ngIf="showIceRink && eventType && requiresIceRink(eventType)"
    >
      <app-ice-rink
        [game]="game"
        [showPlayerPosition]="showPlayerPosition"
        [ngClass]="{ swapped: swapSide, 'not-swapped': !swapSide }"
      ></app-ice-rink>
    </div>
    <mat-slide-toggle
      class="input-toggle"
      [color]="'primary'"
      [checked]="showInput"
      (change)="changeShowInputFields($event)"
      matTooltip="Toggle Input Fields"
    >
    </mat-slide-toggle>
    <app-video-keyboard-actions
      [ngClass]="{
        'keyboard-actions':
          !(showIceRink && requiresIceRink(eventType)) || !eventType
      }"
      [game]="game"
      [hidden]="!showInput"
      (keyTyped)="onKeyTyped()"
    ></app-video-keyboard-actions>
  </div>
  <div class="left">
    <ng-container *ngFor="let mediaId of mediaIds">
      <vg-player
        (onPlayerReady)="onPlayerReady($event, mediaId)"
        (click)="containerClicked($event)"
        *ngIf="mediaId === activeMediaId"
      >
        <app-game-context
          [game]="game"
          [visible]="showGameContext"
          [ngClass]="screensize"
          [swapSides]="swapSide"
          (swap)="swapSide = $event"
        ></app-game-context>
        <ng-container *ngIf="game.dataSet === 'FULL'">
          <app-puck-possession-state [game]="game"></app-puck-possession-state>
        </ng-container>
        <app-game-clock></app-game-clock>
        <app-penalties
          [visible]="showGameContext"
          [swapSide]="swapSide"
          [game]="game"
          [ngClass]="screensize"
        ></app-penalties>

        <app-video-object-tracks
          *ngIf="trackingEnabled"
          [game]="game"
          [frameRate]="frameRate"
          [frameNumber]="currentFrame + trackOffset"
          [onlyNumbers]="onlyNumbers"
          [videoWidth]="videoWidth"
          [videoHeight]="videoHeight"
          [detectionMode]="detectionMode"
          [filteredTeams]="filteredTeams"
        >
        </app-video-object-tracks>

        <app-video-tracking-controls
          *ngIf="trackingEnabled && debugMode"
          [game]="game"
          [currentFrame]="currentFrame"
          [initialFrameRate]="frameRate"
          (debugModeChange)="onlyNumbersChanged($event)"
          (frameRateChange)="frameRateChange($event)"
          (detectionModeChange)="detectionModeChange($event)"
          (teamChange)="filteredTeams = $event"
        ></app-video-tracking-controls>

        <div
          *ngIf="debugMode"
          class="video-stats"
          style="
            position: absolute;
            z-index: 1;
            top: 50px;
            right: 50px;
            color: white;
          "
        >
          <ul>
            <li>Video ID: {{ activeMediaId }}</li>
            <ng-container *ngIf="activeMediaId.includes('webrtc')">
              <li>HLS Seeking: {{ hlsSeeking }}</li>
              <li>Video Bitrate: {{ bitrateMeasurement?.videoBitrate }}</li>
              <li>Audio Bitrate: {{ bitrateMeasurement?.audioBitrate }}</li>
              <li>Target Bitrate: {{ bitrateMeasurement?.targetBitrate }}</li>
              <li
                >Duration:
                {{
                  (connectionStats?.currentTimestamp -
                    connectionStats?.startTime) /
                    1000 | number : '1.1-1'
                }}s</li
              >
            </ng-container>
          </ul>
        </div>

        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-playback-button
            [playbackValues]="['0.5', '1.0', '2.0', '4.0', '0.1', '0.25']"
          ></vg-playback-button>

          <app-vg-time-display
            vgProperty="current"
            vgFormat="hh:mm:ss"
            ignoreLive="true"
            [isWebRTC]="isWebRTC"
            [isHLS]="isHLS"
            [duration]="durationWebRTC"
          ></app-vg-time-display>

          <app-video-live-scrub-bar
            [dvr]="true"
            (switchChannel)="switch($event)"
            [livePosition]="livePosition"
          >
            <app-vg-scrub-bar-current-time
              [isWebRTC]="isWebRTC"
              [livePosition]="livePosition"
            ></app-vg-scrub-bar-current-time>
          </app-video-live-scrub-bar>

          <app-vg-time-display
            (click)="seekToLive()"
            vgProperty="left"
            vgFormat="hh:mm:ss"
            [isWebRTC]="isWebRTC"
            [isHLS]="isHLS"
          ></app-vg-time-display>

          <button *ngIf="hlsSeeking || webRtcExists" (click)="seekToLive()"
            >GO LIVE</button
          >

          <vg-quality-selector
            *ngIf="bitrates"
            [bitrates]="bitrates"
            (onBitrateChange)="setBitrate($event)"
          >
          </vg-quality-selector>
          <div class="tracking" (click)="toggleTracking()">
            <div class="disabled" *ngIf="!trackingEnabled"
              ><mat-icon>gps_off</mat-icon></div
            >
            <div class="enabled" *ngIf="trackingEnabled"
              ><mat-icon>gps_fixed</mat-icon></div
            >
          </div>
          <div class="video">
            <mat-select
              [(value)]="channelIndex"
              *ngIf="game.videos"
              (selectionChange)="switchChannel($event.value)"
              style="width: 200px; padding: 16px; color: white"
            >
              <mat-option
                *ngFor="let video of game.videos; let i = index"
                [value]="i"
              >
                <span class="video-number">{{ i + 1 }} </span>
                <span>{{ video.cameraAngle | titlecase }} </span>
                <span class="video-variant" *ngIf="video.variant">{{
                  video.variant
                }}</span>
                <span class="video-format" *ngIf="video.format">{{
                  video.format?.toUpperCase()
                }}</span>
                <span class="video-offset" *ngIf="video.offset"
                  >+{{ video.offset }}s</span
                >
              </mat-option>
            </mat-select>
          </div>
          <ng-container>
            <button
              mat-icon-button
              title="Edit camera offset"
              class="offset-button"
              *ngIf="!editOffset"
              (click)="editOffset = !editOffset"
              ><mat-icon>edit</mat-icon></button
            >
            <button
              mat-icon-button
              class="offset-button"
              *ngIf="editOffset"
              [disabled]="channelIndex === 0"
              (mousedown)="startFastRewind()"
              (mouseup)="endFastRewind()"
              ><mat-icon>fast_rewind</mat-icon></button
            >
            <button
              mat-icon-button
              class="offset-button"
              *ngIf="editOffset"
              [disabled]="channelIndex === 0"
              (click)="subtractFrameOffset()"
              ><mat-icon>arrow_left</mat-icon></button
            >
            <button
              mat-icon-button
              class="offset-button"
              *ngIf="editOffset"
              [disabled]="channelIndex === 0"
              (click)="addFrameOffset()"
              ><mat-icon>arrow_right</mat-icon></button
            >
            <button
              mat-icon-button
              class="offset-button"
              *ngIf="editOffset"
              [disabled]="channelIndex === 0"
              (mousedown)="startFastForward()"
              (mouseup)="endFastForward()"
              ><mat-icon>fast_forward</mat-icon></button
            >
            <button
              mat-icon-button
              title="Save offsets"
              class="offset-button"
              *ngIf="editOffset"
              (click)="saveOffsets()"
              ><mat-icon>done</mat-icon></button
            >
          </ng-container>

          <vg-volume></vg-volume>
          <vg-mute></vg-mute>

          <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video
          *ngIf="mediaId && mediaId.startsWith('video-vod-') && isMP4"
          [vgMedia]="mediaVOD"
          #mediaVOD
          type="video/mp4"
          [id]="mediaId"
          preload="auto"
          [src]="videoUrl"
        ></video>
        <video
          *ngIf="mediaId && mediaId.startsWith('video-dash-') && isDASH"
          [vgMedia]="mediaDASH"
          #mediaDASH
          [id]="mediaId"
          preload="auto"
          [vgDash]="videoUrl"
          (onGetBitrates)="availableBitrates($event)"
        ></video>
        <video
          *ngIf="mediaId && mediaId.startsWith('video-hls-') && isHLS"
          [vgMedia]="mediaHLS"
          #mediaHLS
          [id]="mediaId"
          preload="auto"
          autoplay
          [vgHls]="videoUrl"
          (onGetBitrates)="availableBitrates($event)"
          [config]="hlsConfig"
        ></video>
        <video
          *ngIf="mediaId && mediaId.startsWith('video-webrtc-') && isWebRTC"
          [vgMedia]="mediaWebRTC"
          #mediaWebRTC
          [id]="mediaId"
          preload="auto"
          autoplay
          muted
          [appWebRTC]="videoUrl"
          (availableBitrates)="availableBitrates($event)"
          (connectionStats)="updateConnectionStats($event)"
          (bitrateMeasurement)="updateBitrateMeasurement($event)"
        ></video>
      </vg-player>
    </ng-container>
  </div>
</div>
