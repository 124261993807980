import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { SeasonType } from '../domain/game';
import { LeagueService, LeagueSummary } from '../services/league.service';
import { SeasonService } from '../services/season.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditLeagueDialogComponent } from './edit-league-dialog/edit-league-dialog.component';

@Component({
  selector: 'app-league-summary',
  templateUrl: './league-summary.component.html',
  styleUrls: ['./league-summary.component.css']
})
export class LeagueSummaryComponent implements OnInit {
  readonly seasonTypes = Object.values(SeasonType);
  dataSource: MatTableDataSource<LeagueSummary>;
  columns = [
    'imageUrl',
    'shortName',
    'statusNew',
    'statusInCollection',
    'statusInExtendedCollection',
    'statusQualityControl',
    'statusComplete',
    'statusFailed',
    'statusAbandoned',
    'actions'
  ];
  seasons = this.seasonService.getSeasons();
  filter: UntypedFormGroup;
  loading: boolean;

  constructor(
    private leagueService: LeagueService,
    private seasonService: SeasonService,
    formBuilder: UntypedFormBuilder,
    private dialog: MatDialog
  ) {
    this.filter = formBuilder.group({
      season: ['2024'],
      // TODO make dependent from current date
      seasonType: ['Regular'],
      collectorTeam: [null],
      hideWithoutGames: [true]
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<LeagueSummary>([]);
    this.filter.valueChanges.subscribe((filter: any) => this.loadData(filter));
    this.filter.patchValue({});
  }

  loadData(filter: any) {
    this.loading = true;
    this.leagueService
      .getSummary(
        filter.season,
        filter.seasonType,
        filter.collectorTeam,
        filter.hideWithoutGames
      )
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((data) => (this.dataSource.data = data));
  }

  getQueryParams(leagueId: string, status?: string) {
    return {
      league: leagueId,
      season: this.filter.value.season,
      seasonType: this.filter.value.seasonType,
      isTrainingGame: false,
      status,
      flags: 'notIsTrainingGame',
      eventCollectorName: this.filter.value.collectorTeam
    };
  }

  teamsQueryParams() {
    return {
      season: this.filter.value.season
    };
  }

  showCreateLeagueDialog(league) {
    const dialogRef = this.dialog.open(EditLeagueDialogComponent, {
      width: '600px',
      data: { league }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.leagueService
          .createOrUpdateLeague({
            ...result
          })
          .subscribe((_league) => {
            const index = this.dataSource.data.findIndex(
              (item) => item.league.id === _league[0].id
            );
            if (index >= 0) {
              this.dataSource.data[index].league = _league[0];
              this.dataSource.data = [...this.dataSource.data];
            } else {
              this.dataSource.data = [
                ...this.dataSource.data,
                { _id: _league[0].sihfId, league: _league[0], byStatus: {} }
              ];
            }
          });
      }
    });
  }

  deleteLeague(leagueSummary) {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          title: `Confirm deletion of ${
            leagueSummary.league.shortName +
            ' - ' +
            leagueSummary.league.fullName
          }`,
          message: `This will hide the league and it's data, but not delete any actual data.`,
          okButtonCaption: 'Delete League'
        }
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.leagueService
            .deleteLeague(leagueSummary.league.id)
            .subscribe(() => {
              const index = this.dataSource.data.findIndex(
                (item) => item.league.id === leagueSummary.league.id
              );
              this.dataSource.data.splice(index, 1);
              this.dataSource._updateChangeSubscription();
            });
        }
      });
  }
}
