import { Component, Input } from '@angular/core';
import { Game } from '../domain/game';

@Component({
  selector: 'app-custom-player-input',
  templateUrl: './custom-player-input.component.html',
  styleUrls: ['./custom-player-input.component.css']
})
export class CustomPlayerInputComponent {
  @Input() positionName: string;
  @Input() positionKey: string;

  @Input() game: Game;
}
