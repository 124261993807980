import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { Team } from '../domain/team';
import { TeamService } from '../services/team.service';

@Component({
  selector: 'app-team-input',
  templateUrl: './team-input.component.html',
  styleUrls: ['./team-input.component.scss']
})
export class TeamInputComponent implements OnInit, OnChanges {
  @Input() placeholder = '';
  @Input() idType = 'id';
  @Input() shortName: string;
  @Input() teamId: string;
  @Input() teams: Team[];
  @Output() teamChange = new EventEmitter<Team>();
  filteredTeams$: Observable<Team[]>;
  teamFormControl = new FormControl<Team>(null, [Validators.required]);

  constructor(private teamService: TeamService) {}

  @Input() set disabled(value: boolean) {
    if (value) {
      this.teamFormControl.disable();
    } else {
      this.teamFormControl.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['teamId']?.currentValue ||
      !changes['shortName']?.currentValue
    ) {
      return;
    }
    const team = {
      id: changes['teamId']?.currentValue,
      shortName: changes['shortName']?.currentValue
    } as Team;
    this.teamFormControl.setValue(team, { emitEvent: false });
  }

  ngOnInit() {
    this.filteredTeams$ = this.teamFormControl.valueChanges.pipe(
      startWith(''),
      switchMap((value: string | Team) => {
        if (typeof value !== 'string') {
          return of([]);
        }
        const query = value?.toLowerCase();
        const filtered = this.teams.filter((t) =>
          t.shortName?.toLowerCase().includes(query)
        );
        if (filtered.length > 0) {
          return of(filtered);
        }
        if (query?.length < 3) {
          return of([]);
        }
        return this.teamService.findTeamsByName(this.idType, query);
      }),
      catchError((err, caught) => {
        console.error('findTeamsByName failed', err);
        return caught;
      })
    );
    if (this.disabled) {
      this.teamFormControl.disable();
    }
  }

  clear() {
    this.teamFormControl.setValue(null);
    this.teamChange.emit(null);
  }

  changeTeam(team: Team) {
    this.teamFormControl.setValue(team);
    this.teamChange.emit(team);
  }

  displayTeamFn(team: Team) {
    if (!team) {
      return null;
    }
    return `${team.shortName}`;
  }
}
