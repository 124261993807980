import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group, Line, TimelinesChartInstance } from 'timelines-chart';
import { Game } from '../../domain/game';
import { Player } from '../../domain/player';
import { Shift } from '../../domain/shift';
import { Store } from '@ngrx/store';
import { videoTimeExternalChange } from '../../state/actions/game.action';
import { GlobalState } from '../../state/reducers';
import { selectedTrackIdChange } from '../../state/actions/game-event.action';

@Component({
  selector: 'app-game-timeline',
  templateUrl: './game-timeline.component.html',
  styleUrls: ['./game-timeline.component.css']
})
export class GameTimelineComponent implements OnInit {
  @ViewChild('timeline', { static: true })
  timeline: ElementRef;

  @Output()
  selectShift = new EventEmitter<Shift>();

  game: Game;
  players: Player[] = [];
  shifts: Shift[] = [];

  initialised = false;
  team: string;
  period: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject('TIMELINES_CHART') private timelinesChart: TimelinesChartInstance,
    private store: Store<GlobalState>
  ) {}

  ngOnInit(): void {
    this.game = this.data.game;
    this.players = this.data.players;
    this.shifts = this.data.shifts;
    this.team = this.game.homeTeam;
    this.period = '1';
    this.initChart();
  }

  initChart() {
    this.initialised = true;
    this.timelinesChart
      .timeFormat('%H:%M:%S.%L')
      .zQualitative(true)
      .rightMargin(200)
      .width(1200)
      .enableOverview(false)
      .data(this.prepareData())
      .onSegmentClick((segment) => {
        console.log('segment clicked', segment);
        const shift = this.findShift(segment);
        if (shift) {
          this.seekToTrack(shift.onEvent.videoTime, shift.onEvent.trackId);
        }
      })(this.timeline.nativeElement);
  }

  updateChart() {
    this.timelinesChart.data(this.prepareData()).refresh();
    const e = this.timeline.nativeElement;
  }

  private prepareData(): Group[] {
    return [
      {
        group: this.team,
        data: this.playerData(this.players.filter((p) => p.team === this.team))
      }
    ];
  }

  private playerData(players: Player[]): Line[] {
    return players
      .filter((p) => p.name)
      .map((player) => {
        const shifts = this.findCompleteShifts(player.playerNumber).filter(
          (s) => s.onEvent.period === this.period
        );
        return {
          label: player.playerNumber,
          data: shifts.map((shift) => ({
            timeRange: [
              this.gameTimeToDate(shift.onEvent.gameTime),
              this.gameTimeToDate(shift.offEvent.gameTime)
            ] as [Date, Date],
            val: this.getPosition(shift.player)
          }))
        };
      })
      .filter((p) => p.data.length > 0);
  }

  private gameTimeToDate(gameTime: number) {
    return new Date(gameTime * 1000 - 3600 * 1000);
  }

  private findShift(segment: any): Shift {
    const playerNumber = segment.label;
    const timeRange = segment.timeRange;
    return this.findCompleteShifts(playerNumber).find(
      (s) =>
        this.gameTimeToDate(s.onEvent.gameTime).getTime() ===
        timeRange[0].getTime()
    );
  }

  private findCompleteShifts(playerNumber: string) {
    return this.shifts
      .filter((s) => s.player.playerNumber === playerNumber)
      .filter((s) => s.onEvent && s.offEvent)
      .filter((s) => s.onEvent.gameTime && s.offEvent.gameTime);
  }

  private seekToTrack(videoTime: number, trackId: number) {
    console.log('seekToTrack', videoTime, trackId);
    this.store.dispatch(
      videoTimeExternalChange({
        videoTimeExternal: videoTime,
        random: self.crypto.randomUUID()
      })
    );
    this.store.dispatch(selectedTrackIdChange({ selectedTrackId: trackId }));
  }

  private getPosition(player: Player): string {
    const position = this.game.getPlayerPositionNameAndIndex(
      player.playerNumber,
      player.team
    );
    return position.split('-')[0];
  }
}
